import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSContextMenu, DSContextMenuButton } from '@hundred5/design-system';
import { useHistory } from 'react-router';

import { copyText } from '@/components/utils/copy-text';
import { ConfirmModal, Icon, TId } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import { useJobOpeningQuery } from '@/features/job-opening';
import { getJobOpeningUrlForCandidate } from '@/features/job-opening/utils/job-opening-url';
import { useJobOpeningPermission } from '@/features/permissions';
import {
  usePipelineCategoriesQuery,
  useRemoveTestFromPipelineCategoryMutation,
} from '@/features/pipeline';
import { getShareTestUpsellTooltipAttributes } from '@/features/workspace';
import { useCanShareTest } from '@/hooks/planLimits';
import { useJobOpeningId, useWorkspaceId } from '@/hooks/router';
import { getJobOpeningPreviewUrl } from '@/utils/urls';

import { useTestQuery } from '../../queries';

interface ContextMenuProps {
  testId: TId;
}

export function ContextMenu({ testId }: ContextMenuProps) {
  const { showFlashMessage } = useFlashMessages();
  const canShareTest = useCanShareTest();
  const history = useHistory();
  const jobOpeningId = useJobOpeningId();
  const workspaceId = useWorkspaceId();

  const [menuOpen, setMenuOpen] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  const pipelineCategoriesQuery = usePipelineCategoriesQuery({
    jobOpeningId: jobOpeningId,
  });
  const jobOpeningQuery = useJobOpeningQuery();
  const testQuery = useTestQuery({ testId });
  const removeTestMutation = useRemoveTestFromPipelineCategoryMutation({
    jobOpeningId,
  });
  const isInJobOpeningWithSingleTest =
    pipelineCategoriesQuery.data?.filter(category => category.testId).length ===
    1;
  const canEditPipeline = !!useJobOpeningPermission()('pipeline', 'update');

  const handleCopyTestLink = () => {
    copyText(
      getJobOpeningUrlForCandidate({
        jobOpeningSlug: jobOpeningQuery.data?.slug!,
        hashedTestId: testQuery.data?.hashedTestId!,
      })
    );
    showFlashMessage({
      type: 'link_copied',
    });
  };

  const handleDeleteTest = async () => {
    await removeTestMutation.mutateAsync({
      category: {
        ...pipelineCategoriesQuery.data?.find(
          category => category.testId === testId
        )!,
        testId: null,
      },
      testId,
    });

    // close modal and go back to main view
    setDeleteModalOpen(false);
    history.push(`/admin/${workspaceId}/openings/${jobOpeningId}/pipeline`);
  };

  const handleTestPreview = () => {
    const url = getJobOpeningPreviewUrl(
      jobOpeningQuery.data!,
      testQuery.data?.type,
      window.location.href
    );

    window.open(url);
  };

  if (!testQuery.isSuccess || !jobOpeningQuery.isSuccess) {
    return null;
  }

  return (
    <ContextMenuContainer>
      <DSContextMenuButton
        active={menuOpen}
        onClick={() => setMenuOpen(!menuOpen)}
      />
      <DSContextMenu open={menuOpen} position={{ top: '32px', right: '0' }}>
        <DSContextMenu.Item
          label="Preview test"
          icon={<Icon icon={regular('eye')} color="purple-60" />}
          onClick={handleTestPreview}
        />
        <div
          {...(!canShareTest(testQuery.data) &&
            getShareTestUpsellTooltipAttributes(testQuery.data, 'right'))}
        >
          <DSContextMenu.Item
            label="Copy test link"
            icon={<Icon icon={regular('link-simple')} color="purple-60" />}
            onClick={handleCopyTestLink}
          />
        </div>
        {canEditPipeline && !isInJobOpeningWithSingleTest ? (
          <>
            <DSContextMenu.Delimiter />
            <DSContextMenu.Item
              label="Delete test"
              icon={<Icon icon={regular('trash')} color="purple-60" />}
              onClick={handleDeleteTest}
            />
          </>
        ) : null}
      </DSContextMenu>
      <ConfirmModal
        open={deleteModalOpen}
        action="Delete"
        onConfirm={handleDeleteTest}
        onClose={() => setDeleteModalOpen(false)}
      >
        <h3>Are you sure?</h3>
        <p>
          This test is going to be deleted permanently with no backups.
          <br />
          Therefore be sure you really wish to do that.
        </p>
      </ConfirmModal>
    </ContextMenuContainer>
  );
}

const ContextMenuContainer = styled.div`
  position: relative;
  margin-left: 8px;
`;
