import React, { useMemo, useState } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button } from '@hundred5/design-system';
import { Player, Recorder } from '@togglhire/video';

import { createTestIntro, deleteTestIntro } from '@/api/tests';
import { ConfirmModal, Icon } from '@/features/common';
import { JobOpeningPageContent } from '@/features/job-opening';
import { Test } from '@/types/h5test';

import './Introduction.css';

export const Introduction = ({ test }: { test: Test | null }) => {
  const [recordingActive, setRecordingActive] = useState(false);
  const [blob, setBlob] = useState<Blob | null>(null);
  const [mediaUrl, setMediaUrl] = useState<string | null>(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const testIntro = useMemo(() => test?.introductionVideoUrl, [test]);
  const resolution = { width: 640, height: 320 };

  return (
    <JobOpeningPageContent.Block
      size={16}
      center
      footer={
        recordingActive ? (
          <div className={'job-openings-video-page__intro_buttons'}>
            <>
              <Button
                onClick={() => {
                  setBlob(null);
                  setMediaUrl(null);
                  setRecordingActive(false);
                }}
                variant="secondary"
                disabled={saving}
              >
                Cancel
              </Button>
              {blob && (
                <>
                  <Button
                    onClick={() => {
                      setBlob(null);
                      setMediaUrl(null);
                    }}
                    variant="secondary"
                    disabled={saving}
                  >
                    Retake
                  </Button>
                  <Button
                    onClick={async () => {
                      if (test?.id) {
                        setSaving(true);
                        await createTestIntro(test?.id, blob);

                        setSaving(false);
                        setRecordingActive(false);
                      }
                    }}
                    disabled={saving}
                  >
                    {saving ? 'Saving...' : 'Save'}
                  </Button>
                </>
              )}
            </>
          </div>
        ) : testIntro ? (
          <div className={'job-openings-video-page__intro_buttons'}>
            <Button
              onClick={() => {
                setConfirmModalOpen(true);
              }}
              variant="secondary"
            >
              Delete
            </Button>
            <Button
              onClick={() => {
                setRecordingActive(true);
              }}
              variant="secondary"
            >
              Retake
            </Button>
          </div>
        ) : null
      }
    >
      <h2>Introductory video</h2>
      <p>
        Make the test more personal. Record a 2-min video describing the
        position and your company.
        <br />
        This video will be shown for every candidate before they start the test.
      </p>

      {!recordingActive && !testIntro && (
        <div className="job-opening-video-page__video_placeholder">
          <Button disabled={!test} onClick={() => setRecordingActive(true)}>
            <Icon icon={solid('video')} size="sm" />
            &nbsp;Record Video
          </Button>
        </div>
      )}
      {!recordingActive && testIntro && <Player src={testIntro} />}
      {recordingActive && mediaUrl && <Player src={mediaUrl} />}
      {recordingActive && !blob && (
        <Recorder
          resolution={resolution}
          onFinish={(blob, mediaUrl) => {
            setBlob(blob);
            setMediaUrl(mediaUrl);
          }}
        />
      )}

      <ConfirmModal
        open={confirmModalOpen}
        action="Confirm"
        onConfirm={async () => {
          if (test) {
            await deleteTestIntro(test?.id);
          }
          setConfirmModalOpen(false);
        }}
        onClose={() => setConfirmModalOpen(false)}
      >
        <h3>Are you sure?</h3>
        <p>
          This will delete your introduction video. This action cannot be
          undone.
        </p>
      </ConfirmModal>
    </JobOpeningPageContent.Block>
  );
};
