import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';
import classNames from 'classnames';
import { format } from 'date-fns/format';

import { useUnlockCandidateMutation } from '@/features/candidate';
import { Icon } from '@/features/common';
import { IPipelineCandidate } from '@/features/pipeline/types';
import { useWorkspaceStatsQuery } from '@/features/workspace';
import { useWorkspaceId } from '@/hooks/router';

import {
  Actions,
  CandidateContainer,
  CandidateContainerBody,
  CandidateContainerFooter,
  CandidateContainerHeader,
  Checkbox,
  Name,
  Tags,
} from '.';

export const LockedCandidateBox = ({
  candidate,
}: {
  candidate: IPipelineCandidate;
}) => {
  const workspaceId = useWorkspaceId();
  const workspaceStats = useWorkspaceStatsQuery({ workspaceId });
  const unlockCandidateMutation = useUnlockCandidateMutation();
  const canUnlock =
    !!workspaceStats.data?.unlockableTestTakersRemaining &&
    workspaceStats.data?.unlockableTestTakersRemaining > 0;
  const handleUnlockCandidate = useCallback(
    (candidate: IPipelineCandidate) => {
      unlockCandidateMutation.mutate({
        candidateId: candidate.id,
      });
    },
    [unlockCandidateMutation]
  );
  return (
    <CandidateContainer
      className={classNames({ actionable: canUnlock, locked: true })}
    >
      <CandidateContainerHeader>
        <Checkbox blured selected={false} disabled={true} onSelect={() => {}} />

        <Name blurred>Candidate {candidate.id}</Name>
        <LockedState>
          <Icon icon={solid('lock')} color="purple-60" />
        </LockedState>
      </CandidateContainerHeader>
      <CandidateContainerBody blured>
        <Tags
          tags={[
            {
              id: '1',
              type: 'date',
              name: format(new Date(), 'MMM d, yyyy'),
            },
          ]}
        />
      </CandidateContainerBody>
      <CandidateContainerFooter>
        <Actions>
          {canUnlock && (
            <DSButton
              size="xsmall"
              onClick={
                canUnlock ? () => handleUnlockCandidate(candidate) : undefined
              }
            >
              Unlock
            </DSButton>
          )}
        </Actions>
      </CandidateContainerFooter>
    </CandidateContainer>
  );
};

const LockedState = styled.div`
  display: flex;
  margin-left: auto;
  font-size: 12px;
`;
