import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import RCPagination, { PaginationProps } from 'rc-pagination';
import Select, { BaseSelectRef, SelectProps } from 'rc-select';
import { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';

import { Icon } from '@/features/common';

// Overriding Select from rc-select to add custom arrow icon
function ItemsPerPageSelect(
  props: SelectProps<any, BaseOptionType | DefaultOptionType> & {
    children?: React.ReactNode;
  } & {
    ref?: React.Ref<BaseSelectRef>;
  }
) {
  return (
    <Select
      {...props}
      suffixIcon={
        <Icon
          icon={regular('chevron-down')}
          color="purple-60"
          fontSize="10px"
        />
      }
    />
  );
}
ItemsPerPageSelect.Option = Select.Option;

export function Pagination(props: PaginationProps) {
  return (
    <Wrapper>
      <RCPagination
        showSizeChanger
        selectComponentClass={ItemsPerPageSelect}
        locale={{ items_per_page: '/ page' }}
        prevIcon={
          <Icon
            icon={regular('chevron-left')}
            color="purple-60"
            fontSize="14px"
          />
        }
        nextIcon={
          <Icon
            icon={regular('chevron-right')}
            color="purple-60"
            fontSize="14px"
          />
        }
        jumpPrevIcon={<>...</>}
        jumpNextIcon={<>...</>}
        {...props}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .rc-pagination {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  .rc-pagination li {
    list-style: none;
  }

  .rc-pagination-item,
  .rc-pagination-jump-prev,
  .rc-pagination-jump-next,
  .rc-pagination-prev,
  .rc-pagination-next {
    font-size: 14px;
    padding: 0 6px;
    min-width: 21px;
    height: 21px;
    text-align: center;
    border-radius: 6px;
    transition: background 0.1s;
    cursor: pointer;
    outline: none;
    user-select: none;

    &:hover {
      background: ${props => props.theme.hover.onWhite};
    }
  }

  .rc-pagination-item a,
  .rc-pagination-jump-prev,
  .rc-pagination-jump-next {
    color: ${props => props.theme.typography.colorSecondary};
  }

  .rc-pagination-item-active a {
    color: ${props => props.theme.typography.colorAccent};
  }

  .rc-pagination-prev,
  .rc-pagination-next {
    svg {
      transition: transform 0.2s;
    }

    &:hover {
      svg {
        transform: scale(0.75);
      }
    }
  }

  .rc-pagination-disabled,
  .rc-pagination-disabled:hover,
  .rc-pagination-disabled:focus,
  .rc-select-disabled,
  .rc-select-disabled:hover,
  .rc-select-disabled:focus {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.4;
  }

  .rc-pagination-options {
    margin-left: 12px;
    height: 24px;
  }

  .rc-select {
    position: relative;
  }

  .rc-select-selector {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 24px;
    width: 108px;
    padding: 0 12px;
    background: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.purple[40]};
    border-radius: 8px;
    outline: none;
    -webkit-appearance: none;
    transition: background 0.1s, box-shadow 0.1s, border 0.1s;

    &:hover {
      background: ${props => props.theme.hover.onWhite};
    }
  }

  .rc-select-open .rc-select-selector {
    box-shadow: ${props => props.theme.shadow.input};
  }

  .rc-select-selection-search,
  .rc-select-selection-search-input {
    display: none;
    appearance: none;
  }

  .rc-select-selection-item {
    pointer-events: none;
    font-size: 12px;
    color: ${props => props.theme.typography.colorSecondary};
  }

  .rc-select-arrow {
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 0;
    height: 24px;
    transition: transform 100ms ease-in-out;
  }

  .rc-select-open .rc-select-arrow {
    transform: rotate(180deg);
    svg {
      color: ${props => props.theme.typography.colorAccent};
    }
  }

  .rc-select-dropdown {
    position: absolute;
    top: 32px !important;
    padding: 4px;
    background: ${props => props.theme.colors.white};
    border-radius: 8px;
    box-shadow: ${props => props.theme.shadow.popup};
    outline: none;
  }

  .rc-select-dropdown-hidden {
    display: none;
  }

  .rc-virtual-list-holder-inner {
    gap: 1px;
  }

  .rc-select-item {
    font-size: 14px;
    color: ${props => props.theme.typography.colorPrimary};
    border-radius: 8px;
    height: 28px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    transition: background 0.1s;
    cursor: pointer;
  }

  .rc-select-item-option-active {
    background: ${props => props.theme.hover.onWhite};
  }

  .rc-select-item-option-state {
    display: none;
  }

  @media all and (-ms-high-contrast: none) {
    .rc-pagination-options *::-ms-backdrop,
    .rc-pagination-options {
      vertical-align: top;
    }
  }
  @media only screen and (max-width: 992px) {
    .rc-pagination-item-after-jump-prev,
    .rc-pagination-item-before-jump-next {
      display: none;
    }
  }
  @media only screen and (max-width: 576px) {
    .rc-pagination-options {
      display: none;
    }
  }
`;
