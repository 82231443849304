import React from 'react';
import styled from '@emotion/styled';
import { TransitionGroup } from 'react-transition-group';

import { CollapseTransition } from '@/features/common';
import { IFlashMessage } from '@/features/flash-messages/types';
import { useFlashMessages } from '@/features/flash-messages/utils';

import { FlashMessage } from './ui/flash-message';

export const FlashMessages = () => {
  const { flashMessages } = useFlashMessages();

  return (
    <Wrapper>
      <NotificationsItemList>
        {flashMessages.map((message: IFlashMessage) => (
          <CollapseTransition key={`flash-message-${message.id}`}>
            <NotificationItem>
              <FlashMessage message={message} />
            </NotificationItem>
          </CollapseTransition>
        ))}
      </NotificationsItemList>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  right: 110px;
  bottom: 10px;
  z-index: 3000;
  pointer-events: none;

  @media all and (max-width: 768px) {
    left: 0;
    right: 0;
  }
`;

const NotificationsItemList = styled(TransitionGroup)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media all and (max-width: 768px) {
    & > div {
      width: 100%;
    }
  }
`;

const NotificationItem = styled.div`
  padding-top: 20px;
  pointer-events: all;
`;
