import React from 'react';
import { TogglThemeProvider } from '@hundred5/design-system';
import { QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router } from 'react-router-dom';

import AxiosInterceptorsHandler from '@/components/app/AxiosInterceptorsHandler';
import { AuthProvider } from '@/features/authentication';
import { AuthenticationProvider } from '@/features/authentication/providers/authentication.context';
import { FlashMessagesProvider } from '@/features/flash-messages';
import { queryClient } from '@/utils/reactQuery';

interface EnvironmentProps {
  children: React.ReactNode;
}

export function Environment(props: EnvironmentProps) {
  return (
    <TogglThemeProvider>
      <AuthenticationProvider>
        <FlashMessagesProvider>
          <AuthProvider />
          <Router>
            <QueryClientProvider client={queryClient}>
              <AxiosInterceptorsHandler>
                {props.children}
              </AxiosInterceptorsHandler>
            </QueryClientProvider>
          </Router>
        </FlashMessagesProvider>
      </AuthenticationProvider>
    </TogglThemeProvider>
  );
}
