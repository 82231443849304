import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '@/features/common';
import { useClickOutside } from '@/features/common/hooks/use-click-outside';

interface Suggestion {
  id?: string;
  value: string;
  locked?: boolean;
}

interface AutocompleteProps {
  value: string;
  isActive?: boolean;
  suggestions: Suggestion[];
  maxInputLength?: number;
  onSuggestionSelect: (suggestion: string) => void;
  onChange: (event?: React.ChangeEvent<HTMLInputElement>) => void;
  onClickOutside: () => void;
}

export function Autocomplete(props: AutocompleteProps) {
  const autoCompleteRef = useRef<null | HTMLDivElement>(null);
  useClickOutside(autoCompleteRef, props.onClickOutside);

  const [filteredSuggestions, setFilteredSuggestions] = useState<Suggestion[]>(
    []
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setFilteredSuggestions(
      props.suggestions.filter(
        suggestion =>
          suggestion.value.toLowerCase().indexOf(value.toLowerCase()) > -1
      )
    );

    if (props.onChange) {
      props.onChange(event);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      props.onSuggestionSelect(event.currentTarget.value);
    }
  };

  useEffect(() => {
    if (!props.isActive) {
      setFilteredSuggestions([]);
    }
  }, [props.isActive]);

  return (
    <>
      <Container ref={autoCompleteRef}>
        <Input
          value={props.value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          autoFocus
          maxLength={props.maxInputLength}
        ></Input>

        {filteredSuggestions.length > 0 && (
          <Suggestions>
            {filteredSuggestions.map((suggestion, idx) => (
              <SuggestionsItem
                tabIndex={idx}
                key={suggestion.id || suggestion.value}
                isLocked={
                  suggestion && suggestion.locked ? suggestion.locked : false
                }
                onClick={() => {
                  if (!suggestion.locked) {
                    props.onSuggestionSelect!(suggestion.value);
                  }
                }}
              >
                {suggestion.locked && (
                  <Icon icon={regular('lock')} color="grey" />
                )}
                <SuggestionsItemLabel>{suggestion.value}</SuggestionsItemLabel>
              </SuggestionsItem>
            ))}
          </Suggestions>
        )}
      </Container>
    </>
  );
}

//region Styles
const Container = styled.div`
  position: relative;
`;

const Input = styled.input`
  font-size: 12px;
  padding: 3px 12px;
  background: #ffffff;
  border: 1px solid #aba0af;
  border-radius: 8px;
  width: 200px;
  min-height: 24px;
  outline: 0;
`;

const Suggestions = styled.ul`
  position: absolute;
  padding: 3px 4px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(44, 19, 56, 0.2);
  border-radius: 8px;
  width: 200px;
  max-height: 300px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  list-style: none;
  z-index: 2;
`;

const SuggestionsItemLabel = styled.span`
  display: inline-block;
  margin-left: 5px;
`;

const SuggestionsItem = styled.li<{ isLocked: boolean }>`
  width: 100%;
  color: #2c1338;
  margin: 2px 0;
  padding: 3px 12px;

  &:hover {
    background: #f4f3f5;
    border-radius: 8px;
    cursor: pointer;
  }

  ${props =>
    props.isLocked === true &&
    `
    opacity: .7;

    &:hover {
      background: transparent;
      cursor: not-allowed;
    }

    ${SuggestionsItemLabel} {
      margin-left: 5px;
    }
  `}
`;
//endregion
