import React, { useMemo } from 'react';
import * as amplitude from '@amplitude/analytics-browser';

import {
  useDeleteSubscriptionMutation,
  useSubscribeModal,
} from '@/features/billing';
import { ConfirmModal } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import { useMeQuery } from '@/features/me';
import { useHistory, useWorkspaceId } from '@/hooks/router';

interface ResumeModalProps {
  resumeModalState: boolean;
  setResumeModalState: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ResumeModal({
  resumeModalState,
  setResumeModalState,
}: ResumeModalProps) {
  const workspaceId = useWorkspaceId();
  const { openSubscribeModal } = useSubscribeModal();
  const history = useHistory();
  const meQuery = useMeQuery();

  const deleteSubscriptionMutation = useDeleteSubscriptionMutation({
    onSuccess: () => {
      currentPlan && openSubscribeModal(currentPlan);
      amplitude?.logEvent(`billing/subscription resumed`, {
        'workspace id': workspaceId,
      });
      showFlashMessage({ type: 'saved' });
      history.push(`/admin/${workspaceId}/settings/billing`);
    },
  });
  const { showFlashMessage } = useFlashMessages();

  const currentPlan = useMemo(() => {
    return (
      meQuery.data?.workspaces?.find(workspace => workspace.id === workspaceId)
        ?.subscriptionPlan ?? null
    );
  }, [workspaceId, meQuery.data?.workspaces]);

  return (
    <ConfirmModal
      open={resumeModalState}
      action="Confirm"
      onConfirm={() => {
        setResumeModalState(false);
        deleteSubscriptionMutation.mutate();
      }}
      onClose={() => setResumeModalState(false)}
      confirmation="Resume"
    >
      <h3>Are you sure?</h3>
      <p>You are about to resume your subscription. Do you want to proceed?</p>
    </ConfirmModal>
  );
}
