import axios, { AxiosRequestHeaders } from 'axios';

import { getGoogleReCaptchaToken } from '@/features/google-recaptcha';

import { ReCaptchaError } from '../errors';

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL + '/api/v1',
  withCredentials:
    import.meta.env.VITE_SHARED_AUTH_ENABLED === 'true' ? true : false,
});

const configuration: { authToken: string | null } = {
  authToken: null,
};

api.interceptors.request.use(request => {
  if (configuration.authToken) {
    request.headers = {
      Authorization: `Bearer ${configuration.authToken}`,
      ...request.headers,
    } as AxiosRequestHeaders;
  }

  return request;
});

api.interceptors.request.use(async request => {
  if (request.captchaAction) {
    try {
      const token = await getGoogleReCaptchaToken(request.captchaAction);
      request.headers = ({
        'captcha-token': token,
        ...request.headers,
      } as unknown) as AxiosRequestHeaders;
    } catch (error) {
      throw new ReCaptchaError(error);
    }
  }

  return request;
});

export default api;

export function setAuthToken(authToken: string) {
  configuration.authToken = authToken;
}

export function deleteAuthToken() {
  configuration.authToken = null;
}
