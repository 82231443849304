import React, { memo, useCallback, useMemo, useRef } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';
import classNames from 'classnames';

import {
  getCandidateNameOrEmail,
  isCandidateAnonymizedRecommendation,
} from '@/features/candidate';
import { Icon } from '@/features/common';
import { IJobOpening } from '@/features/job-opening';
import { useJobOpeningPermission } from '@/features/permissions';
import { useTestQuery } from '@/features/test';
import { getShareTestUpsellTooltipAttributes } from '@/features/workspace';
import { useCanShareTest } from '@/hooks/planLimits';
import { useWorkspaceId } from '@/hooks/router';

import { IPipelineCandidate } from '../../types';

import { logOpenCandidateCard } from './candidate-box.utils';
import {
  Actions,
  CandidateContainer,
  CandidateContainerBody,
  CandidateContainerFooter,
  CandidateContainerHeader,
  CandidateContainerNotes,
  CandidateState,
  Checkbox,
  Name,
  Tags,
} from './ui';
import { LockedCandidateBox } from './ui/locked-candidate-box';
import { CandidateSystemTags } from './ui/system-tags';

interface CandidateBoxProps {
  candidate: IPipelineCandidate;
  onClick: (candidate: IPipelineCandidate) => void;
  onInvite: (candidate: IPipelineCandidate) => void;
  onResendInvitation: (candidate: IPipelineCandidate) => void;
  onReset: (candidate: IPipelineCandidate) => void;
  jobOpening?: IJobOpening;
  onSelect?: (candidate: IPipelineCandidate) => void;
  selected?: boolean;
  active?: boolean;
}

export function CandidateBox({
  candidate,
  onClick,
  onSelect,
  onInvite,
  onResendInvitation,
  onReset,
  selected,
  active,
}: CandidateBoxProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const workspaceId = useWorkspaceId();
  const canShareTest = useCanShareTest();
  const canUpdateCandidate = useJobOpeningPermission()('candidate', 'update');

  const { actionable, name, test } = useMemo(() => {
    const test = candidate.tests?.find(
      test => test.categoryId === candidate.categoryId
    );

    return {
      actionable: !candidate.isDemo && canUpdateCandidate,
      name: getCandidateNameOrEmail(candidate),
      tags: candidate.previousCandidateId
        ? [{ id: 0, name: 'reapplied' }, [...(candidate.tags ?? [])]]
        : candidate.tags,
      test,
    };
  }, [candidate, canUpdateCandidate]);
  const testQuery = useTestQuery({ testId: test?.testId }); // FIXME: this could be avoided by adding missing fields to IPipelineCandidate in api responses
  const showUpsell = !(testQuery.isSuccess && canShareTest(testQuery.data));
  const upsellTooltipAttributes = useMemo(
    () =>
      testQuery.isSuccess
        ? getShareTestUpsellTooltipAttributes(testQuery.data)
        : {},
    [testQuery.data, testQuery.isSuccess]
  );

  const handleClick = useCallback(() => {
    logOpenCandidateCard(workspaceId, candidate.id);
    onClick(candidate);
  }, [candidate, onClick, workspaceId]);

  const handleSelect = useCallback(
    event => {
      if (!onSelect) {
        return;
      }

      event.stopPropagation();
      onSelect(candidate);
    },
    [candidate, onSelect]
  );

  const inviteCandidate = useCallback(
    event => {
      event.stopPropagation();
      onInvite(candidate);
    },
    [candidate, onInvite]
  );

  const resendInvitation = useCallback(
    event => {
      event.stopPropagation();
      onResendInvitation(candidate);
    },
    [candidate, onResendInvitation]
  );

  function resetCandidateTest() {
    onReset(candidate);
  }

  function renderAction() {
    switch (test?.candidateState) {
      case 'not_invited':
        return (
          <DSButton
            size="xsmall"
            onClick={inviteCandidate}
            {...(showUpsell && upsellTooltipAttributes)}
          >
            Invite
          </DSButton>
        );
      case 'invited':
      case 'interested':
        return (
          <DSButton
            variant="secondary"
            size="xsmall"
            onClick={resendInvitation}
            {...(showUpsell && upsellTooltipAttributes)}
          >
            Resend
          </DSButton>
        );
      case 'out_of_time':
        return (
          <DSButton
            type="button"
            variant="secondary"
            size="xsmall"
            onClick={event => {
              event.stopPropagation();
              resetCandidateTest();
            }}
          >
            Reset test
          </DSButton>
        );
    }
  }

  return (
    <>
      {candidate.isUnlocked ||
      isCandidateAnonymizedRecommendation(candidate) ? (
        <CandidateContainer
          className={classNames({ actionable: actionable })}
          onClick={handleClick}
          ref={containerRef}
          active={active}
        >
          <CandidateContainerHeader>
            {onSelect ? (
              <Checkbox
                selected={selected}
                onSelect={handleSelect}
                disabled={isCandidateAnonymizedRecommendation(candidate)}
              />
            ) : null}
            <Name unseenCandidate={!candidate.seen} data-recording-sensitive>
              {name}
            </Name>
            {test ? <CandidateState test={test} active={active} /> : null}
          </CandidateContainerHeader>
          <CandidateContainerBody>
            <CandidateSystemTags candidate={candidate} active={active} />
          </CandidateContainerBody>
          <CandidateContainerFooter>
            <Tags tags={candidate.tags} />
            {candidate.notesTotal > 0 ? (
              <CandidateContainerNotes>
                <Icon icon={solid('file-pen')} size="xs" color="purple-60" />
                {candidate.notesTotal}
              </CandidateContainerNotes>
            ) : null}
            <Actions>{renderAction()}</Actions>
          </CandidateContainerFooter>
        </CandidateContainer>
      ) : (
        <LockedCandidateBox candidate={candidate} />
      )}
    </>
  );
}

export const MemoizedCandidateBox = memo(CandidateBox);
