import { Id } from '../types/misc';
import { User } from '../types/user';
import { Workspace, WorkspaceUser } from '../types/workspace';

import api from './api';

export type FetchMeData = {
  user: User;
  workspaces: { [id: string]: Workspace };
  workspaceUsers: { [id: string]: WorkspaceUser };
  ownershipTransferRequests: { [id: string]: Id };
  supportMode: boolean;
  emailVerified: boolean | null;
  intercomUserHash: string;
  surveyCompleted: boolean | null;
};

export async function deleteMe(): Promise<void> {
  await api.delete('me');
}

export async function exportMe(): Promise<string> {
  const response = await api.post('/me/takeout');
  const token = response.data.token;

  return `${import.meta.env.VITE_API_URL}/api/v1/me/takeout?token=${token}`;
}
