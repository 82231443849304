import React from 'react';
import styled from '@emotion/styled';

import { usePopover } from './usePopover';

type TPosition = 'top' | 'bottom' | 'left' | 'right';

export interface ContentProps {
  children:
    | ((props) => React.ReactNode | JSX.Element[] | null)
    | React.ReactNode;
  position?: TPosition;
}

export const Content = ({
  position = 'bottom',
  children,
  ...props
}: ContentProps) => {
  const { isOpen, close } = usePopover();

  return isOpen ? (
    <PopoverContent position={position} {...props}>
      {typeof children === 'function' ? children({ close }) : children}
    </PopoverContent>
  ) : null;
};

//region styles
const PopoverContent = styled.div<{ position: TPosition }>`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(44, 19, 56, 0.2);
  display: flex;
  flex-direction: column;
  min-width: 200px;
  padding: 4px;
  position: absolute;
  z-index: 1000;

  ${({ position }) => {
    switch (position) {
      case 'top':
        return `
        top: -4px;
        left: 0;
        transform: translateY(-100%);
      `;
      case 'bottom':
        return `
        bottom: -4px;
        left: 0;
        transform: translateY(100%);
      `;
      case 'left':
        return `
        left: -4px;
        top: 0;
        transform: translateX(-100%);
      `;
      case 'right':
        return `
        right: -4px;
        top: 0;
        transform: translateX(100%);
      `;
    }
  }}
`;
//endregion
