import React, { useEffect, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';

import { useDocumentTitle } from '@/features/common';
import { TTestType, TestType } from '@/features/test/types';
import { useWorkspaceId } from '@/hooks/router';

import { HomeworkLibrary } from '../homework-library';
import { SkillTestLibrary } from '../skill-test-library';
import { VideoIntroLibrary } from '../video-intro-library';

import { TestTypeTab } from './ui';

export function TestLibrary() {
  useDocumentTitle('Test Library');
  useAnalytics();
  const [selectedTestType, setSelectedTestType] = useState<TTestType>(
    TestType.Quiz
  );

  return (
    <Container>
      <TestTypeSelector>
        <TestTypeList>
          <TestTypeTab
            type={TestType.Quiz}
            active={selectedTestType === TestType.Quiz}
            onClick={() => setSelectedTestType(TestType.Quiz)}
          />
          <TestTypeTab
            type={TestType.Video}
            active={selectedTestType === TestType.Video}
            onClick={() => setSelectedTestType(TestType.Video)}
          />
          <TestTypeTab
            type={TestType.Homework}
            active={selectedTestType === TestType.Homework}
            onClick={() => setSelectedTestType(TestType.Homework)}
          />
        </TestTypeList>
      </TestTypeSelector>
      <TestLibraryContainer>
        {selectedTestType === TestType.Quiz && <SkillTestLibrary />}
        {selectedTestType === TestType.Video && <VideoIntroLibrary />}
        {selectedTestType === TestType.Homework && <HomeworkLibrary />}
      </TestLibraryContainer>
    </Container>
  );
}

const useAnalytics = () => {
  const workspaceId = useWorkspaceId();

  useEffect(() => {
    amplitude?.logEvent('page view/open template gallery', {
      'workspace id': workspaceId,
    });
  }, [workspaceId]);
};

const Container = styled.div`
  background-color: ${props => props.theme.colors.white};
  margin: 12px 24px;
  box-shadow: 0px 1px 4px 0px rgba(44, 19, 56, 0.1);
  border-radius: 8px;

  @media all and (max-width: 768px) {
    margin: 0px;
    border-radius: 0px;
  }
`;

const TestLibraryContainer = styled.div`
  padding: 0;
  background-color: ${props => props.theme.colors.white};
  border-radius: 0 0 8px 8px;
`;

const TestTypeSelector = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${props => props.theme.colors.peach[50]};
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;

  @media all and (max-width: 768px) {
    padding: 0px;
    background-color: transparent;
  }
`;

const TestTypeList = styled.div`
  display: flex;
  flex-direction: row;
`;
