import { useCallback, useEffect, useMemo } from 'react';
import { camelizeKeys } from 'humps';
import { isEmpty, isEqual, omit, omitBy } from 'lodash';
import qs from 'qs';

import { stringifyParams } from '@/features/common';
import { useStoreData } from '@/features/storage';
import { useHistory, useLocation } from '@/hooks/router';

const PROTECTED_PARAMS = ['page', 'itemsPerPage', 'sortOrder', 'sortDirection'];

export function useDefaultSearchParams(
  defaultParams: object,
  storeParams: object | null
) {
  const location = useLocation();
  const { replace } = useHistory();

  useEffect(() => {
    const params = {
      ...defaultParams,
      ...storeParams,
    };

    if (!isEmpty(params) && !location.search) {
      replace({
        search: `?${stringifyParams(params)}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export function useSearchParams<
  T extends {
    page: string;
  }
>(defaultParams, storageKey) {
  const location = useLocation();
  const { push } = useHistory();
  const [storeParams, setStoreParams] = useStoreData<Omit<T, 'page'>>(
    storageKey
  );

  const searchParams: T = useMemo(() => {
    return location.search
      ? (camelizeKeys(
          qs.parse(location.search.substring(1), { comma: true })
        ) as T)
      : { ...defaultParams, ...storeParams };
  }, [location, defaultParams, storeParams]);

  const removeSearchParam = useCallback(
    (param: Extract<keyof T, string>) => {
      if (PROTECTED_PARAMS.includes(param)) return;
      const nextParams = { ...searchParams };
      delete nextParams[param];

      if (!isEqual(searchParams, nextParams)) {
        push({ ...location, search: `?${stringifyParams<T>(nextParams)}` });
        setStoreParams(omit(nextParams, ['page']));
      }
    },
    [push, searchParams, location, setStoreParams]
  );

  const addSearchParams = useCallback(
    (params: Partial<T>) => {
      const filteredParams = omitBy(params, value => !Boolean(value));

      const nextParams: T = {
        ...searchParams,
        ...filteredParams,
      };

      if (!isEqual(searchParams, nextParams)) {
        push({
          pathname: location.pathname,
          search: `?${stringifyParams(nextParams)}`,
        });
        setStoreParams(omit(nextParams, ['page']));
      }
    },
    [push, searchParams, location.pathname, setStoreParams]
  );

  const clearSearchParams = useCallback(() => {
    const nextParams: T = defaultParams;
    if (!isEqual(searchParams, nextParams)) {
      push({ ...location, search: `?${stringifyParams(nextParams)}` });
      setStoreParams(omit(nextParams, ['page']));
    }
  }, [defaultParams, searchParams, push, location, setStoreParams]);

  const hasSearchParams = useMemo(() => {
    const defaultParamsKeys = Object.keys(defaultParams);
    defaultParamsKeys.push('q'); // also ignore search field

    return Object.keys(searchParams).some(
      key => !defaultParamsKeys.includes(key)
    );
  }, [defaultParams, searchParams]);

  return {
    searchParams,
    hasSearchParams,
    addSearchParams,
    removeSearchParam,
    clearSearchParams,
  };
}
