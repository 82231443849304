import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import {
  CANDIDATE_HISTORY_QUERY_KEY,
  CANDIDATE_QUERY_KEY,
  ICandidate,
  updateCandidateContactInfo,
  updateCandidateCustomFields,
} from '@/features/candidate';
import { TId } from '@/features/common';
import { PIPELINE_CANDIDATES_KEY } from '@/features/pipeline';

export const useUpdateCandidateContactInfoMutation = (
  options?: UseMutationOptions
) => {
  const { stageId: categoryId, opening: jobOpeningId } = useParams<{
    stageId: TId;
    opening: TId;
  }>();
  const queryClient = useQueryClient();

  return useMutation(updateCandidateContactInfo, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        [...CANDIDATE_QUERY_KEY, variables.candidateId],
        (oldCandidate: ICandidate) => ({
          ...oldCandidate,
          contactInfo: data,
        })
      );

      queryClient.invalidateQueries([
        ...PIPELINE_CANDIDATES_KEY,
        jobOpeningId,
        categoryId,
      ]);
      queryClient.invalidateQueries([
        ...CANDIDATE_HISTORY_QUERY_KEY,
        variables.candidateId,
      ]);
    },

    ...(options as any),
  });
};

export const useUpdateCandidateCustomFieldsMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation(updateCandidateCustomFields, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        [...CANDIDATE_QUERY_KEY, variables.candidateId],
        (oldCandidate: ICandidate) => ({
          ...oldCandidate,
          candidateFields: data,
        })
      );

      queryClient.invalidateQueries([
        ...CANDIDATE_HISTORY_QUERY_KEY,
        variables.candidateId,
      ]);
    },

    ...(options as any),
  });
};
