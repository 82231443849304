import qs from 'qs';

import { IDashboardJobOpening } from '@/features/dashboard';
import { IJobOpening } from '@/features/job-opening';

export const getJobOpeningPreviewUrl = (
  jobOpening: IJobOpening | IDashboardJobOpening,
  previewPageType?: string,
  backHref?: string
): string => {
  const { slug, previewToken } = jobOpening;
  const params = qs.stringify(
    {
      ['token']: previewToken,
      ['type']: previewPageType ? previewPageType : null,
      ['back_href']: backHref ? backHref : null,
    },
    {
      skipNulls: true,
      addQueryPrefix: true,
    }
  );
  return `${import.meta.env.VITE_CANDIDATE_APP_URL}/preview/${slug}${params}`;
};

export const addHttp = url => {
  if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
    url = 'http://' + url;
  }
  return url;
};

export const isValidHttpUrl = (v: string) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(v);
};
