import React from 'react';
import styled from '@emotion/styled';
import {
  DSButton,
  DSField,
  DSInput,
  DSModal,
  DSTextArea,
} from '@hundred5/design-system';
import { Form, Formik } from 'formik';
import { array, object, string } from 'yup';

import {
  FormikRadioField,
  FormikSelectField,
  FormikTextAreaField,
} from '@/features/common';
import {
  APPLICATION_QUESTION_TYPES,
  IJobOpeningApplicationFormQuestion,
  TJobOpeningApplicationFormQuestionEdit,
  TApplicationQuestionOptionEdit,
} from '@/features/job-opening';

import { QuestionAnswerOptions } from '.';

interface AddEditQuestionModalProps {
  open: boolean;
  question?: IJobOpeningApplicationFormQuestion;
  onClose: () => void;
  onSave: (values: TJobOpeningApplicationFormQuestionEdit) => void;
}

const validationSchema = object().shape({
  type: string().required("This can't be blank!"),
  question: string()
    .required("This can't be blank!")
    .max(150, "Question can't be longer than 150 characters!"),
  options: array()
    .nullable()
    .when('type', {
      is: type => type === 'single-choice' || type === 'multiple-choice',
      then: schema =>
        schema
          .required()
          .typeError('Add at least two answer choices!')
          .min(2, 'Add at least two answer choices!')
          .test(
            'option-length',
            "Each choice can't be longer than 150 characters!",
            (options: TApplicationQuestionOptionEdit[]) => {
              if (options && options.some(opt => opt.option.length > 150)) {
                return false;
              }
              return true;
            }
          ),
    }),
});

export function AddEditQuestionModal({
  open,
  question,
  onClose,
  onSave,
}: AddEditQuestionModalProps) {
  const initialValues: TJobOpeningApplicationFormQuestionEdit = {
    type: question?.type || 'paragraph',
    question: question?.question || '',
    required: question?.required || false,
    options: question?.options || null,
  };

  return (
    <DSModal open={open} onClose={onClose} contentStyle={{ maxWidth: 612 }}>
      <DSModal.CloseButton small fixed onClick={onClose} />
      <Header>{question ? 'Edit question' : 'Add question'}</Header>
      <DSModal.Separator />

      <Formik<TJobOpeningApplicationFormQuestionEdit>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSave}
      >
        {({ values, touched, errors, dirty, setFieldValue }) => (
          <Form>
            <Content>
              <OptionRow>
                <FormikRadioField
                  name="required"
                  label="Optional"
                  checked={!values.required}
                  onChange={() => setFieldValue('required', false)}
                />
                <FormikRadioField
                  name="required"
                  label="Mandatory"
                  checked={values.required}
                  onChange={() => setFieldValue('required', true)}
                />
              </OptionRow>

              <FormikSelectField
                label="Type"
                name="type"
                required
                items={APPLICATION_QUESTION_TYPES}
                onChange={type => {
                  setFieldValue('type', type);

                  if (type === 'paragraph' || type === 'short-answer') {
                    setFieldValue('options', null);
                    return;
                  }

                  if (type === 'single-choice' && !!values.options?.length) {
                    const oneCorrect =
                      values.options?.filter(option => option.isCorrect)
                        .length === 1;

                    if (oneCorrect) return;

                    setFieldValue(
                      'options',
                      values.options.map(option => ({
                        ...option,
                        isCorrect: false,
                      }))
                    );
                  }
                }}
              />

              <FormikTextAreaField
                label="Question"
                name="question"
                placeholder="Type your question here..."
                inputHeight="80px"
                required
              />

              {values.type === 'paragraph' && (
                <DSField label="Answer" for="answer" disabled>
                  <DSTextArea
                    id="answer"
                    placeholder="Candidates can give a longer answer."
                    height="80px"
                    disabled
                  />
                </DSField>
              )}

              {values.type === 'short-answer' && (
                <DSField label="Answer" for="answer" disabled>
                  <DSInput
                    id="answer"
                    placeholder="Candidates can give a short answer."
                    disabled
                  />
                </DSField>
              )}

              {(values.type === 'single-choice' ||
                values.type === 'multiple-choice') && (
                <DSField
                  label="Choices"
                  for="options"
                  error={
                    touched.options && !!errors.options ? errors.options : null
                  }
                  errorAbsolute
                >
                  <QuestionAnswerOptions
                    type={values.type}
                    options={values.options}
                    onChange={options => setFieldValue('options', options)}
                  />
                </DSField>
              )}
            </Content>
            <DSModal.Footer float="right">
              <DSButton type="submit" disabled={!dirty}>
                {question ? 'Save' : 'Add'}
              </DSButton>
            </DSModal.Footer>
          </Form>
        )}
      </Formik>
    </DSModal>
  );
}

const Header = styled.h2`
  color: ${props => props.theme.typography.colorPrimary};
  margin-top: 0;
  margin-bottom: 16px;
`;

const Content = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const OptionRow = styled.div`
  display: flex;
  gap: 16px;
`;
