import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '@/features/common';
import { ITemplate } from '@/features/test-library/types';

type TemplateProps = {
  template: ITemplate;
  onClick: () => void;
};

export function Template({ template, onClick }: TemplateProps) {
  return (
    <TemplateButton
      onClick={() => {
        onClick && onClick();
        amplitude?.logEvent('job templates/click on template', {
          name: template.primaryName,
          premium: template.isPremium,
        });
      }}
    >
      {template.isPremium && (
        <PremiumBadge>
          <Icon icon={regular('crown')} width={10} height={9} />
        </PremiumBadge>
      )}
      {template.primaryName}
    </TemplateButton>
  );
}

const TemplateButton = styled.button`
  background-color: ${props => props.theme.colors.purple[5]};
  color: ${props => props.theme.colors.purple[100]};
  border-radius: 8px;
  border: none;
  box-shadow: 0px 1px 4px 0px rgba(44, 19, 56, 0.1);
  padding: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  min-width: 322px;
  height: 66px;
  position: relative;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.purple[10]};
  }

  @media all and (max-width: 768px) {
    width: 100%;
  }
`;

const PremiumBadge = styled.div`
  background-color: ${props => props.theme.colors.orange[30]};
  color: ${props => props.theme.colors.purple[100]};
  border-radius: 8px;
  width: 26px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: absolute;
  top: 8px;
  right: 8px;
`;
