import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';

import { ISkill } from '@/features/skills';
import { ITemplate } from '@/features/test-library/types';

import { Template } from './template';

interface TemplateTypeProps {
  templatesByType?: { [id: string]: ITemplate[] };
  templateTypeName?: string;
  templates?: ITemplate[];
  skills: ISkill[] | undefined;
  createCustomTest: () => void;
  onTemplateSelected: (template?: ITemplate) => void;
}

export function TemplateType({
  templateTypeName,
  templatesByType,
  onTemplateSelected,
}: TemplateTypeProps) {
  return (
    <Container>
      <Header>
        <h3>{templateTypeName || 'Search results'}</h3>
      </Header>
      <Content>
        {templatesByType &&
          templateTypeName &&
          templatesByType[templateTypeName]?.map(template => {
            return (
              <Template
                key={template.id}
                template={template}
                onClick={() => {
                  amplitude?.logEvent('job templates/click template', {
                    'template name': template.primaryName,
                    page: 'template gallery',
                  });

                  onTemplateSelected(template);
                }}
              />
            );
          })}
      </Content>
    </Container>
  );
}

const Container = styled.section`
  background: ${props => props.theme.colors.white};
  padding: 0 36px;
  overflow: hidden;

  @media all and (max-width: 768px) {
    padding: 0 8px;
  }
`;

const Header = styled.div`
  color: ${props => props.theme.typography.colorPrimary};
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  h3 {
    margin: 0;
  }

  @media all and (max-width: 768px) {
    margin-bottom: 16px;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(322px, 1fr));
  row-gap: 12px;
  column-gap: 24px;
  padding-bottom: 4px;

  @media all and (max-width: 768px) {
    margin: 0 -8px;
    padding: 0 8px 4px;
    overflow-x: scroll;
    width: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }
  }
`;
