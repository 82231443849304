import React, { useLayoutEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { ICandidateTag } from '@/features/candidate';

import { Tag } from './tag';
import { TagsList } from './tags-list';

export interface ICandidateSystemTag {
  id: string;
  name?: string;
  type:
    | 'date'
    | 'suspicious_flag'
    | 'test_score'
    | 'test_score_overthreshold'
    | 'demo';
  icon?: React.ReactNode;
}
type TagsProps = {
  tags: ICandidateTag[] | ICandidateSystemTag[] | null;
  active?: boolean;
};

export function Tags({ tags, active }: TagsProps) {
  const ref = useRef<HTMLUListElement>(null);
  const [hiddenTagsCount, setHiddenTagsCount] = useState<number>(0);
  const [lastTagOffsetRight, setLastTagOffsetRight] = useState<number>(0);

  useLayoutEffect(() => {
    const parentElement = ref.current;
    if (!parentElement) {
      return;
    }

    let overflowedElements = 0;
    for (let i = 0; i < parentElement.children.length; i++) {
      const childElement = parentElement.children[i] as HTMLElement;
      if (childElement.offsetTop !== parentElement.offsetTop) {
        overflowedElements++;
      } else {
        setLastTagOffsetRight(
          parentElement.offsetWidth -
            childElement.offsetLeft -
            childElement.offsetWidth
        );
      }
    }

    setHiddenTagsCount(overflowedElements);
  }, [hiddenTagsCount, setHiddenTagsCount, tags]);

  return (
    <TagListContainer>
      <TagsList ref={ref}>
        {tags?.map(tag => (
          <Tag key={tag.id} type={tag.type} active={active}>
            {tag.icon}
            {tag.name && (
              <span>{tag.name[0].toUpperCase() + tag.name.slice(1)}</span>
            )}
          </Tag>
        ))}
      </TagsList>
      {hiddenTagsCount > 0 && (
        // 8px gap beetween tags
        <CountTag offset={lastTagOffsetRight - 8} active={active}>
          <span>+{hiddenTagsCount}</span>
        </CountTag>
      )}
    </TagListContainer>
  );
}

const TagListContainer = styled.div`
  display: flex;
  grid-area: tags;
  position: relative;
`;

const CountTag = styled(Tag)<{ offset: number }>`
  position: relative;
  left: -${({ offset }) => offset}px;
`;
