import React from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSGrid } from '@hundred5/design-system';

import { useWorkspaceTreatments } from '@/components/splits';
import { Icon } from '@/features/common';
import { ActionBanner } from '@/features/common/components/action-banner/action-banner';
import {
  PersonalInfo,
  ProfileActions,
  ProfileSettings,
  ProfileSettingsActions,
  ProfileSettingsGmail,
} from '@/features/me';
import { getSharedAuthEnabled } from '@/features/toggl-accounts';
import { WorkspaceSettingsPage } from '@/features/workspace';
import { useWorkspaceIdOrNull } from '@/hooks/router';

const ProfileSettingsPage = () => {
  const workspaceId = useWorkspaceIdOrNull();
  const sharedAuthEnabled = getSharedAuthEnabled();
  const { treatments } = useWorkspaceTreatments(['fe_admin_app_emails_tab']);

  // TODO: adding temporary workspaceId check in case /admin/profile is accessed without workspaceId, then Gmail settings component is breaking
  const isEmailIntegrationVisible =
    workspaceId && treatments.fe_admin_app_emails_tab.treatment === 'on';

  return (
    <WorkspaceSettingsPage title="Profile settings">
      <DSGrid gap="20px">
        <ActionBanner
          icon={
            <Icon
              icon={solid('briefcase-blank')}
              color="white"
              fontSize="18px"
            />
          }
          title="First, create a job"
          subTitle="Start assessing candidates in less than 2 minutes"
          actionTitle="Get started"
          isGetStartedBanner
        />
        {sharedAuthEnabled ? (
          <ProfileSettings />
        ) : (
          <>
            <PersonalInfo />
            <ProfileActions />
          </>
        )}

        {isEmailIntegrationVisible && <ProfileSettingsGmail />}
        {sharedAuthEnabled && <ProfileSettingsActions />}
      </DSGrid>
    </WorkspaceSettingsPage>
  );
};

export default ProfileSettingsPage;
