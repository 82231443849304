export const getGoogleReCaptchaToken = (action: string): Promise<string> => {
  return new Promise((res, rej) => {
    window.grecaptcha.ready(() => {
      try {
        window.grecaptcha
          .execute(import.meta.env.VITE_RECAPTCHA_SITE_KEY, { action })
          .then(token => {
            return res(token);
          })
          .catch(error => {
            return rej(error);
          });
      } catch (error) {
        rej(error);
      }
    });
  });
};
