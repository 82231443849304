import React, { useMemo } from 'react';
import styled from '@emotion/styled';

import { ICandidateTestState } from '@/features/candidate';
import { IPipelineCandidateTest } from '@/features/pipeline/types';

import { StateContainer } from './state-container';
import { StatePill } from './state-pill';

interface CandidateStateProps {
  test: IPipelineCandidateTest;
  active?: boolean;
}

export function CandidateState({ test, active }: CandidateStateProps) {
  const label = useMemo(() => {
    switch (test.candidateState) {
      case 'invited':
      case 'in_progress':
      case 'not_invited':
      case 'interested':
      case 'out_of_time':
        return (
          test.candidateState[0].toUpperCase() +
          test.candidateState.slice(1).replaceAll('_', ' ')
        );
      case 'completed':
        if (test.percent > 0) {
          return `${Math.floor(test.percent)}%`;
        }
        return `Completed`;
    }
  }, [test]);

  return (
    <StateContainer>
      <Pill
        state={test.candidateState}
        overThreshold={
          test.percent === 0 ||
          Math.floor(test.percent) >= (test.percentToPass || 60)
        }
        active={active}
        data-rh={
          test.candidateState === 'not_invited'
            ? 'This candiate is not yet invited to the test.'
            : undefined
        }
      >
        {label}
      </Pill>
    </StateContainer>
  );
}

const Pill = styled(StatePill)<{
  state: ICandidateTestState | null;
  overThreshold: boolean;
  active?: boolean;
}>`
  background: ${({ theme, state, overThreshold, active }) => {
    if (active) {
      return theme.colors.white;
    } else {
      switch (state) {
        case 'invited':
        case 'interested':
        case 'in_progress':
          return theme.colors.yellow[50];
        case 'not_invited':
          return theme.colors.purple[10];
        case 'out_of_time':
          return theme.colors.peach[40];
        case 'completed':
          if (!overThreshold) {
            return theme.colors.orange[10];
          }

          return theme.colors.green[10];
      }
    }
  }};
  color: ${({ theme, state, overThreshold }) => {
    switch (state) {
      case 'completed':
        if (!overThreshold) {
          return theme.colors.orange[120];
        }
        return theme.colors.green[100];

      default:
        return theme.colors.purple[100];
    }
  }};
`;
