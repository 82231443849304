import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { format } from 'date-fns/format';
import { isEqual, uniqueId } from 'lodash';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { AnalyticsStatType, DailyStats } from '@/types/analytics';
import { DailyJobOpeningStats } from '@/types/reports';

interface MainChartProps {
  dailyJobOpeningStats?: DailyJobOpeningStats | null;
  selectedAnalyticsStat: AnalyticsStatType;
}

const VIEW_PROPS: {
  [key in AnalyticsStatType]: { title: string; unit: string; color: string };
} = {
  totalVisits: {
    title: 'Visitors',
    unit: 'visit',
    color: '#FF897A',
  },
  totalCandidates: {
    title: 'Applicants',
    unit: 'applicant',
    color: '#20c7ba',
  },
  completionPercentage: {
    title: 'Completion',
    unit: '%',
    color: '#f5a732',
  },
  possibleHires: {
    title: 'Candidates over threshold',
    unit: 'candidate',
    color: '#9062b0',
  },
  averageCompletionTime: {
    title: 'Average time spent',
    unit: 'second',
    color: '#1798a0',
  },
  averageScore: {
    title: 'Average score',
    unit: '%',
    color: '#f3ca37',
  },
};

export const JobOpeningAnalyticsMainChart: React.FC<MainChartProps> = React.memo(
  ({ dailyJobOpeningStats, selectedAnalyticsStat }) => {
    const viewProps = VIEW_PROPS[selectedAnalyticsStat];
    const dailyStats =
      dailyJobOpeningStats && dailyJobOpeningStats[selectedAnalyticsStat];

    const id = uniqueId();
    const data = useMemo(
      () =>
        dailyStats?.map((o: DailyStats) => ({
          ...o,
          date: format(o.date, 'MMM d'),
        })) ?? [],
      [dailyStats]
    );

    return (
      <MainCharContainer>
        <Title>{viewProps.title}</Title>
        <ResponsiveContainer width="100%" height={320} key={id}>
          <BarChart data={data}>
            <CartesianGrid vertical={false} />
            <XAxis dataKey="date" axisLine={false} tickLine={false} />
            <YAxis axisLine={false} tickLine={false} />
            <Tooltip
              content={({ active, payload, label }) => {
                const value = payload && payload[0] ? payload[0].value : null;

                if (active) {
                  return (
                    <TooltipContent>
                      <div>{label}</div>
                      <div>
                        {value} {viewProps.unit}
                        {value !== 1 && viewProps.unit !== '%' ? 's' : ''}
                      </div>
                    </TooltipContent>
                  );
                }
              }}
            />

            <Bar dataKey="value" fill={viewProps.color} />
          </BarChart>
        </ResponsiveContainer>
      </MainCharContainer>
    );
  },
  isEqual
);

const MainCharContainer = styled.div`
  padding: 34px 43px;
  background: ${props => props.theme.colors.white};
  flex: 1;
  overflow: hidden;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.colors.github};
  margin-bottom: 50px;
`;

const TooltipContent = styled.div`
  margin: 0;
  padding: 10px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.purple[20]};
  white-space: nowrap;
`;
