import { useCallback, useMemo } from 'react';
import { find, orderBy } from 'lodash';

import { TId } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import { usePreferenceMutation, usePreferencesQuery } from '@/features/me';
import { usePipelineCategoriesQuery } from '@/features/pipeline';

import {
  useEmailTemplateVariablesQuery,
  useEmailTemplatesQuery,
} from '../queries';
import { IEmailTemplate, TEmailTemplateType } from '../types';
import { convertVariablesFromCamelCase, isSystemTemplate } from '../utils';

export const useEmailTemplates = () => {
  const { data: emailTemplates } = useEmailTemplatesQuery();

  const sortedEmailTemplates = emailTemplates?.length
    ? orderBy(
        emailTemplates.filter(template => template.status === 'active'),
        [
          template => {
            if (isSystemTemplate(template)) return 1; // system
            return 2; // others
          },
          template => template.name.toLowerCase(),
        ],
        ['asc']
      )
    : [];

  return sortedEmailTemplates;
};

export const useSelectedEmailTemplatesByTypeAndTestId = (
  testId: TId | undefined,
  templateType: TEmailTemplateType | undefined,
  jobOpeningId?: TId | null
) => {
  const { data: emailTemplates } = useEmailTemplatesQuery();
  const { data: userPreferences } = usePreferencesQuery();
  const updateUserPreference = usePreferenceMutation();
  const pipelineCategoriesQuery = usePipelineCategoriesQuery(
    { jobOpeningId: jobOpeningId! },
    {
      enabled: !!jobOpeningId,
    }
  );
  const hasPipelineTests = useMemo(
    () => pipelineCategoriesQuery.data?.find(item => item.testId !== null),
    [pipelineCategoriesQuery]
  );
  /* 
  Get last used by the user email template for the given test and template type, select system template if user has not selected any.
  In cases where we don't have a test set yet per job, we will be saving them as ${templateType}_noTest
  For 'contact-candidate' (and 'gmail-candidate') by default we don't select any system template. 
  */
  const currentEmailTemplate: IEmailTemplate | null = useMemo(
    () =>
      (userPreferences &&
        find(emailTemplates, {
          id: userPreferences.selectedEmailTemplatesByTypeAndTestId[
            hasPipelineTests ? `${templateType}_${testId}` : `${templateType}`
          ]!,
        })) ||
      (templateType !== 'contact-candidate' &&
      templateType !== 'gmail-candidate' &&
      emailTemplates?.length
        ? emailTemplates.find(
            template =>
              isSystemTemplate(template) &&
              template.status === 'active' &&
              template.type === templateType
          ) || null
        : null),
    [userPreferences, emailTemplates, templateType, testId, hasPipelineTests]
  );

  const setCurrentEmailTemplate = useCallback(
    (emailTemplateId: TId | null) => {
      userPreferences?.selectedEmailTemplatesByTypeAndTestId &&
        updateUserPreference.mutate({
          name: 'selectedEmailTemplatesByTypeAndTestId',
          value: {
            ...userPreferences.selectedEmailTemplatesByTypeAndTestId,
            [hasPipelineTests
              ? `${templateType}_${testId}`
              : `${templateType}`]: emailTemplateId,
          },
        });
    },
    [
      updateUserPreference,
      userPreferences?.selectedEmailTemplatesByTypeAndTestId,
      templateType,
      testId,
      hasPipelineTests,
    ]
  );
  return {
    setCurrentEmailTemplate,
    currentEmailTemplate,
  };
};

export const useEmailTemplateVariables = () => {
  const { showFlashMessage } = useFlashMessages();

  const { data: emailTemplateVariables } = useEmailTemplateVariablesQuery();

  const emailTemplateVariablesUsedAreValid = useCallback(
    (textWithVariables: string) => {
      const variableRegex = /\[.*?\]/g;
      const usedVariables = textWithVariables.match(variableRegex);

      const invalidVariable = usedVariables?.find(
        usedVariable =>
          !emailTemplateVariables?.find(
            variable => `[${variable.placeholder}]` === usedVariable
          )
      );

      if (invalidVariable) {
        showFlashMessage({
          type: 'email_template_variable_error',
          variable: convertVariablesFromCamelCase(invalidVariable),
        });
        return false;
      }
      return true;
    },
    [emailTemplateVariables, showFlashMessage]
  );

  return {
    emailTemplateVariables: emailTemplateVariables || [],
    emailTemplateVariablesUsedAreValid,
  };
};
