import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Theme, useTheme } from '@emotion/react';
import { Formik } from 'formik';
import { includes } from 'lodash';
import { object, string } from 'yup';

import {
  CardTokenFormBag,
  fetchRecurlyHostedPage,
  useUpdateBillingInfoMutation,
} from '@/features/billing';
import { useWorkspaceId } from '@/hooks/router';
import {
  configureRecurly,
  destroyRecurly,
  generateRecurlyToken,
  RecurlyError,
  RecurlyToken,
} from '@/recurly';

import { CountryField, RecurlyField, RegularField } from './ui';

export interface CardTokenFormProps {
  onFinish: (token: RecurlyToken) => void;
  children: (bag: CardTokenFormBag) => React.ReactNode;
  formRef?: any;
}

let formId = 1;

export const CardTokenForm = (props: CardTokenFormProps) => {
  const id = useMemo(() => formId++, []);
  const workspaceId = useWorkspaceId();
  const formElement = useRef<HTMLFormElement>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<RecurlyError | null>(null);
  const [recurlyLoadFailed, setRecurlyLoadFailed] = useState<boolean>(false);
  const [recurlyHostedPage, setRecurlyHostedPage] = useState('');
  const recurly = useRef<any>(null);
  const theme = useTheme();

  const updateBillingInfoMutation = useUpdateBillingInfoMutation();

  useEffect(() => {
    const config = createRecurlyConfig(id, theme);
    const promise = configureRecurly(config);

    promise
      .then(instance => {
        recurly.current = instance;
      })
      .catch(() => {
        setRecurlyLoadFailed(true);
      });

    return () => {
      promise.then(instance => {
        destroyRecurly(instance);
      });
    };
  }, [id, theme]);

  useEffect(() => {
    fetchRecurlyHostedPage(workspaceId).then(setRecurlyHostedPage);
  }, [workspaceId]);

  const handleSubmit = async values => {
    if (formElement.current == null) {
      throw new Error('Form element has not been registered in card form');
    }

    if (recurly.current == null) {
      throw new Error('Recurly instance has not been created');
    }

    setError(null);
    setSubmitting(true);

    await updateBillingInfoMutation.mutateAsync(
      {
        billingInfo: {
          firstName: values.first_name,
          lastName: values.last_name,
          address: [values.address1, values.address2],
          city: values.city,
          state: values.state,
          country: values.country,
          postalCode: values.postal_code,
          vatNumber: values.vat_number,
          workspace: workspaceId,
        },
        attributes: {
          firstName: values.first_name,
          lastName: values.last_name,
          address: values.address1,
          address2: values.address2,
          city: values.city,
          state: values.state,
          country: values.country,
          postalCode: values.postal_code,
          vatNumber: values.vat_number,
        },
      },
      {
        onSuccess: () => {
          setSubmitting(false);
        },
      }
    );

    let token: RecurlyToken;
    try {
      token = await generateRecurlyToken(recurly.current, formElement.current);
    } catch (error) {
      setError(error);
      setSubmitting(false);
      return;
    }
    setSubmitting(false);
    props.onFinish(token);
  };

  const bag = {
    isSubmitting: submitting,
    errorMessage: getErrorMessage(error),
    recurlyLoadFailed,
    recurlyHostedPage,
    fields: {
      firstName: (
        <RegularField
          name="first_name"
          label="First name"
          placeholder="Ben"
          hasRecurlyError={hasFieldError(error, 'first_name')}
          required
        />
      ),
      lastName: (
        <RegularField
          name="last_name"
          label="Last name"
          placeholder="du Monde"
          hasRecurlyError={hasFieldError(error, 'last_name')}
          required
        />
      ),
      address1: (
        <RegularField
          name="address1"
          label="Street address"
          placeholder="1313 Main St."
          hasRecurlyError={hasFieldError(error, 'address1')}
          required
        />
      ),
      address2: (
        <RegularField
          name="address2"
          label="Apartment, suite"
          placeholder="Unit 1"
          hasRecurlyError={hasFieldError(error, 'address2')}
        />
      ),
      city: (
        <RegularField
          name="city"
          label="City"
          placeholder="Hope"
          hasRecurlyError={hasFieldError(error, 'city')}
          required
        />
      ),
      state: (
        <RegularField
          name="state"
          label="State"
          placeholder="WA"
          hasRecurlyError={hasFieldError(error, 'state')}
        />
      ),
      postalCode: (
        <RegularField
          name="postal_code"
          label="Postal code"
          placeholder="90210"
          hasRecurlyError={hasFieldError(error, 'postal_code')}
          required
        />
      ),
      country: (
        <CountryField
          name="country"
          label="Country"
          hasRecurlyError={hasFieldError(error, 'country')}
          required
        />
      ),
      cardNumber: (
        <RecurlyField
          name="number"
          label="Card number"
          hasRecurlyError={hasFieldError(error, 'number')}
          required
        />
      ),
      month: (
        <RecurlyField
          name="month"
          label=""
          hasRecurlyError={hasFieldError(error, 'month')}
          required
        />
      ),
      year: (
        <RecurlyField
          name="year"
          label=""
          hasRecurlyError={hasFieldError(error, 'year')}
          required
        />
      ),
      cvv: (
        <RecurlyField
          name="cvv"
          label=""
          hasRecurlyError={hasFieldError(error, 'cvv')}
        />
      ),
      vatNumber: (
        <RegularField
          name="vat_number"
          label="VAT number"
          placeholder="SE0000"
          hasRecurlyError={hasFieldError(error, 'vat_number')}
        />
      ),
    },
  };

  return (
    <Formik
      initialValues={{
        first_name: '',
        last_name: '',
        vat_number: '',
        address1: '',
        address2: '',
        country: '',
        city: '',
        state: '',
        postal_code: '',
      }}
      validationSchema={schema}
      onSubmit={handleSubmit}
      innerRef={props.formRef}
    >
      {form => (
        <form
          data-card-token-form={id}
          onSubmit={e => {
            e.preventDefault();
            form.handleSubmit(e);
          }}
          ref={formElement}
          data-recording-ignore="mask"
        >
          {props.children(bag)}
        </form>
      )}
    </Formik>
  );
};

const hasFieldError = (error: RecurlyError | null, field: string) => {
  if (error == null) {
    return false;
  }

  return includes(error.fields, field);
};

const getErrorMessage = (error: RecurlyError | null): string | null => {
  if (error == null) {
    return null;
  }

  switch (error.code) {
    case 'validation':
      return 'Sorry, something is not right with your card details. Check the highlighted fields and try again.';
    default:
      return 'Sorry, something went wrong. Please try again, or contact us if the problem persists.';
  }
};

const createRecurlyConfig = (id: number, theme: Theme) => {
  return {
    publicKey: import.meta.env.VITE_RECURLY_PUBLIC_KEY,
    fields: {
      all: {
        style: {
          color: theme.typography.colorPrimary,
          fontSize: '14px',
          placeholder: {
            color: theme.typography.colorSecondary,
          },
        },
      },
      number: {
        selector: `[data-card-token-form="${id}"] [data-recurly=number]`,
        style: {
          placeholder: { content: '•••• •••• •••• ••••' },
        },
      },
      month: {
        selector: `[data-card-token-form="${id}"] [data-recurly=month]`,
        style: {
          placeholder: { content: 'MM' },
        },
      },
      year: {
        selector: `[data-card-token-form="${id}"] [data-recurly=year]`,
        style: {
          placeholder: { content: 'YYYY' },
        },
      },
      cvv: {
        selector: `[data-card-token-form="${id}"] [data-recurly=cvv]`,
        style: {
          placeholder: { content: '•••' },
        },
      },
    },
  };
};

const schema = object().shape({
  first_name: string().required("This can't be blank!"),
  last_name: string().required("This can't be blank!"),
  vat_number: string(),

  address1: string().required("This can't be blank!"),
  address2: string(),
  city: string().required("This can't be blank!"),
  state: string(),
  postal_code: string().required("This can't be blank!"),
  country: string().required('Select a country'),
});
