import React, { useCallback, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { DSButton, DSModal } from '@hundred5/design-system';
import { object } from 'yup';

import { BulkActionModal } from '@/features/bulk-actions/components/ui/bulk-action-modal';
import { useBulkInviteToNewJob } from '@/features/bulk-actions/queries';
import { BulkActionModalProps } from '@/features/bulk-actions/types';
import { JobOpeningSelector } from '@/features/common/components/job-opening-selector';
import { PipelineStepSelector } from '@/features/common/components/pipeline-step-selector';
import {
  EmailList,
  emailMessageValidationSchema,
  emailSubjectValidationSchema,
  FormikEmailBody,
  FormikEmailForm,
  IEmailFormValuesBase,
} from '@/features/email';

const schema = object().shape({
  subject: emailSubjectValidationSchema,
  message: emailMessageValidationSchema,
});

export const BulkInviteToNewJobModal = ({
  open,
  onClose,
  onActionDone,
  candidates,
}: BulkActionModalProps) => {
  const [jobOpeningId, setJobOpeningId] = useState<string>('');
  const [pipelineStageId, setPipelineStageId] = useState<string>('');
  const [testId, setTestId] = useState<string | null>(null);
  const [interviewId, setInterviewId] = useState<string | null>(null);
  const [sending, setSending] = useState(false);

  const bulkInviteToNewJob = useBulkInviteToNewJob();

  const handleSubmit = useCallback(
    async (values: IEmailFormValuesBase) => {
      setSending(true);
      bulkInviteToNewJob.mutate(
        {
          candidateIds: candidates.map(candidate => candidate.id),
          pipelineStageId: Number(pipelineStageId),
          subject: values.subject,
          message: values.message,
        },
        { onSuccess: onActionDone, onSettled: () => setSending(false) }
      );
      amplitude?.logEvent('bulk actions/bulk action applied', {
        'user ids': candidates,
        'job opening id': jobOpeningId,
        'pipeline step': pipelineStageId,
        'action origin': jobOpeningId ? 'pipeline' : 'all candidates',
        'action type': 'send_email',
      });
    },
    [
      bulkInviteToNewJob,
      candidates,
      jobOpeningId,
      onActionDone,
      pipelineStageId,
    ]
  );
  return (
    <BulkActionModal open={open} onClose={onClose}>
      <BulkActionModal.Header>
        <BulkActionModal.Title>
          Invite candidates to a new job
        </BulkActionModal.Title>
        <DSModal.CloseButton onClick={onClose} fixed small />
      </BulkActionModal.Header>
      <BulkActionModal.Content dark>
        <JobSelectionWrapper>
          <JobOpeningSelector onChange={setJobOpeningId} />
          <PipelineStepSelector
            jobOpeningId={jobOpeningId}
            onChange={pipelineCategory => {
              setPipelineStageId(pipelineCategory.id);
              setTestId(pipelineCategory.testId);
              setInterviewId(pipelineCategory.interviewId);
            }}
          />
        </JobSelectionWrapper>
      </BulkActionModal.Content>
      <BulkActionModal.Content>
        <EmailList emails={candidates.map(candidate => candidate.email)} />
      </BulkActionModal.Content>
      <FormikEmailForm
        initialValues={{ subject: '', message: '' }}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ isValid, errors }) => (
          <>
            <BulkActionModal.Content>
              <FormikEmailBody
                emailType="invitation"
                interviewId={interviewId}
                testId={testId ?? undefined}
              />
            </BulkActionModal.Content>

            <BulkActionModal.Footer>
              <DSButton
                type="submit"
                disabled={
                  !jobOpeningId || !pipelineStageId || sending || !isValid
                }
                loading={bulkInviteToNewJob.isLoading}
                {...((errors.subject || errors.message) && {
                  'data-rh': 'An e-mail must have subject and body.',
                  'data-rh-at': 'top',
                })}
              >
                Invite Candidates
              </DSButton>
            </BulkActionModal.Footer>
          </>
        )}
      </FormikEmailForm>
    </BulkActionModal>
  );
};

const JobSelectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;
