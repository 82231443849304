import { useWorkspaceTreatments } from '@/components/splits';
import { useMeQuery } from '@/features/me';
import { useWorkspace } from '@/features/workspace';
import { getPlanName } from '@/hooks/planLimits';

export function useEmailBanner() {
  const { data: me } = useMeQuery();
  const workspace = useWorkspace();
  const planName = getPlanName(workspace?.subscriptionPlan!);
  const isCorrectPlan = planName === 'business' || planName === 'premium';
  const { treatments } = useWorkspaceTreatments(['fe_admin_app_emails_tab']);
  const isEmailIntegrationVisible =
    treatments.fe_admin_app_emails_tab.treatment === 'on';

  // Split treatment for not showing the banner on prod
  const showBanner =
    isEmailIntegrationVisible &&
    isCorrectPlan &&
    workspace?.gmailEnabled &&
    me?.gmailStatus !== 'enabled';

  return { showBanner };
}
