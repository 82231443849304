import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import {
  CANDIDATE_EMAILS_QUERY_KEY,
  CANDIDATE_HISTORY_QUERY_KEY,
  deleteCandidate,
  exportCandidate,
  fetchCandidate,
  ICandidate,
  markCandidateAsSeen,
  rejectCandidate,
  restoreCandidate,
  unlockCandidate,
  updateCandidate,
} from '@/features/candidate';
import { TId } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import { IJobOpening, JOB_OPENING_QUERY_KEY } from '@/features/job-opening';
import { PIPELINE_CANDIDATES_KEY } from '@/features/pipeline/queries/pipeline-candidates';
import { WORKSPACE_STATS_QUERY_KEY } from '@/features/workspace';
import { useJobOpeningIdOrNull, useWorkspaceId } from '@/hooks/router';

export const CANDIDATE_QUERY_KEY = ['candidate'];
export const CANDIDATE_ANSWER_VIDEO_URL_QUERY_KEY = ['candidateAnswerVideoUrl'];
//region Queries
export const useCandidateQuery = (
  params: { candidateId: TId },
  options?: UseQueryOptions
) => {
  return useQuery(
    [...CANDIDATE_QUERY_KEY, params.candidateId],
    () => fetchCandidate(params),
    {
      staleTime: 5 * 60 * 1000, //  5 minutes of stale time
      ...(options as any),
    }
  );
};

export const useCandidateAnswerVideoUrlQuery = (
  params: { candidateId: TId; testId: TId; questionId: TId },
  options?: UseQueryOptions
) => {
  return useQuery<ICandidate, unknown, string | null>(
    [
      ...CANDIDATE_ANSWER_VIDEO_URL_QUERY_KEY,
      params.candidateId,
      params.testId,
      params.questionId,
    ],
    () => fetchCandidate(params),
    {
      staleTime: 60 * 60 * 1000, //  1h of stale time
      select: (data: ICandidate): string | null => {
        const candidateTest =
          data?.tests.find(test => test.testId === params.testId) || undefined;
        const answer = candidateTest?.answers?.find(
          answer => answer.questionId === params.questionId
        );
        return answer?.recordingUrl || null;
      },
      ...(options as any),
    }
  );
};
//endregion

//region Mutations
export const useUnlockCandidateMutation = (options?: UseMutationOptions) => {
  const jobOpeningId = useJobOpeningIdOrNull();
  const queryClient = useQueryClient();
  const { showFlashMessage } = useFlashMessages();

  return useMutation(unlockCandidate, {
    onSuccess: ({ unlockableTestTakersRemaining }, { candidateId }) => {
      void queryClient.invalidateQueries([...CANDIDATE_QUERY_KEY, candidateId]);

      queryClient.setQueryData(
        [...JOB_OPENING_QUERY_KEY, jobOpeningId],
        (oldJobOpening: IJobOpening) => ({
          ...oldJobOpening,
          unlockableTestTakersRemaining,
        })
      );

      queryClient.invalidateQueries(PIPELINE_CANDIDATES_KEY);

      showFlashMessage({ type: 'candidate_unlocked' });
    },
    ...(options as any),
  });
};

export const useUpdateCandidateMutation = (options?: UseMutationOptions) =>
  useMutation(updateCandidate, {
    onSuccess: useOnUpdateCandidate(),
    ...(options as any),
  });

export const useExportCandidateMutation = (options?: UseMutationOptions) =>
  useMutation(exportCandidate, options as any);

export const useDeleteCandidateMutation = (options?: UseMutationOptions) =>
  useMutation(deleteCandidate, options as any);

export const useRejectCandidateMutation = (options?: UseMutationOptions) =>
  useMutation(rejectCandidate, {
    onSuccess: useOnUpdateCandidate(),
    ...(options as any),
  });

export const useRestoreCandidateMutation = (options?: UseMutationOptions) =>
  useMutation(restoreCandidate, {
    onSuccess: useOnUpdateCandidate(),
    ...(options as any),
  });

export const useMarkCandidateAsSeenMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation(markCandidateAsSeen, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        [...CANDIDATE_QUERY_KEY, variables.candidateId],
        (cachedCandidate: ICandidate) => ({
          ...cachedCandidate,
          seen: true,
        })
      );

      queryClient.invalidateQueries(PIPELINE_CANDIDATES_KEY);
    },
    ...(options as any),
  });
};
//endregion

//region Util hooks
const useOnUpdateCandidate = () => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();

  return (data: ICandidate, { candidateId }: { candidateId: TId }) => {
    queryClient.setQueryData(
      [...CANDIDATE_QUERY_KEY, candidateId],
      (cachedCandidate: ICandidate) => ({ ...cachedCandidate, ...data })
    );

    queryClient.invalidateQueries(PIPELINE_CANDIDATES_KEY);

    queryClient.invalidateQueries([
      ...CANDIDATE_HISTORY_QUERY_KEY,
      candidateId,
    ]);
    queryClient.invalidateQueries([...CANDIDATE_EMAILS_QUERY_KEY, candidateId]);

    queryClient.invalidateQueries([...WORKSPACE_STATS_QUERY_KEY, workspaceId]);
  };
};
//endregion
