import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSSelect } from '@hundred5/design-system';

import { Icon, TId } from '@/features/common';
import { isSystemTemplate, useEmailTemplates } from '@/features/email';
import { usePipelineCategoriesQuery } from '@/features/pipeline';
import { useWorkspaceId } from '@/hooks/router';

interface IProps {
  currentEmailTemplateId?: TId | null;
  onChange?: (emailTemplateId: TId) => void;
  disabled?: boolean;
  alwaysShowPlaceholder?: boolean;
  dropdownPosition?: {
    right?: string;
    left?: string;
  };
  workspaceTemplatesOnly?: boolean;
  addSystemTemplatePlaceholder?: boolean;
  jobOpeningId?: TId | null;
}

//region Main
export const EmailTemplatesSelector = ({
  currentEmailTemplateId,
  onChange,
  disabled,
  alwaysShowPlaceholder = true,
  dropdownPosition = { right: '0' },
  workspaceTemplatesOnly = false,
  addSystemTemplatePlaceholder = false,
  jobOpeningId,
}: IProps) => {
  const workspaceId = useWorkspaceId();
  const emailTemplates = useEmailTemplates();
  const pipelineCategoriesQuery = usePipelineCategoriesQuery(
    { jobOpeningId: jobOpeningId! },
    { enabled: !!jobOpeningId }
  );
  const hasPipelineTests = useMemo(
    () => pipelineCategoriesQuery.data?.find(item => item.testId !== null),
    [pipelineCategoriesQuery]
  );

  const emailTemplatesItems = useMemo(() => {
    const templates = emailTemplates
      .filter(
        emailTemplate =>
          !workspaceTemplatesOnly || !isSystemTemplate(emailTemplate)
      )
      .map(emailTemplate => ({
        id: emailTemplate.id,
        label: emailTemplate.name,
        labelText: emailTemplate.name,
        disabled:
          !!hasPipelineTests ||
          emailTemplate.type === 'rejection' ||
          emailTemplate.type === 'interview-invitation'
            ? false
            : true,
      }));

    return addSystemTemplatePlaceholder
      ? [
          { id: '', label: 'System Template', labelText: 'System Template' },
          ...templates,
        ]
      : templates;
  }, [
    emailTemplates,
    workspaceTemplatesOnly,
    addSystemTemplatePlaceholder,
    hasPipelineTests,
  ]);

  return (
    <StyledDSSelect
      Icon={<Icon icon={regular('layer-group')} />}
      withSearchBar
      placeholder="Templates"
      alwaysShowPlaceholder={alwaysShowPlaceholder}
      selectedItemId={currentEmailTemplateId}
      items={emailTemplatesItems}
      onChange={emailTemplate =>
        emailTemplate && onChange && onChange(emailTemplate.id)
      }
      disabled={disabled}
      Footer={
        <Footer href={`/admin/${workspaceId}/settings/email-templates`}>
          Manage templates
        </Footer>
      }
      dropdownPosition={dropdownPosition}
    />
  );
};
//endregion

const StyledDSSelect = styled(DSSelect)<{
  dropdownPosition: { right?: string; left?: string };
}>`
  .dropdown {
    width: 324px;
    max-height: 404px;
    right: ${({ dropdownPosition }) => dropdownPosition.right ?? 'auto'};
    left: ${({ dropdownPosition }) => dropdownPosition.left ?? 'auto'};
  }
`;

const Footer = styled.a`
  color: ${({ theme }) => theme.typography.colorPrimary};

  &:hover {
    font-weight: 600;
  }
`;
