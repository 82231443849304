import styled from '@emotion/styled';

export const Tag = styled.li<{
  type?:
    | 'date'
    | 'suspicious_flag'
    | 'test_score'
    | 'test_score_overthreshold'
    | 'demo';
  active?: boolean;
}>`
  align-items: center;
  background: ${({ theme, type, active }) => {
    if (active) {
      return theme.colors.white;
    } else {
      switch (type) {
        case 'suspicious_flag':
          return theme.colors.red[10];
        case 'demo':
        case 'date':
          return theme.colors.purple[5];
        case 'test_score':
          return theme.colors.orange[10];
        case 'test_score_overthreshold':
          return theme.colors.green[10];
        default:
          return theme.colors.purple[10];
      }
    }
  }};
  border-radius: 8px;
  display: flex;
  gap: 4px;
  height: 16px;
  justify-content: center;
  padding: 0 8px;
  white-space: nowrap;
  color: ${({ theme, type }) => {
    switch (type) {
      case 'test_score':
        return theme.colors.orange[120];
      case 'test_score_overthreshold':
        return theme.colors.green[100];
      default:
        return theme.colors.purple[100];
    }
  }};

  svg {
    color: ${({ theme, type }) => {
      switch (type) {
        case 'test_score':
          return theme.colors.orange[120];
        case 'test_score_overthreshold':
          return theme.colors.green[100];
        default:
          return theme.colors.purple[100];
      }
    }};
  }
  span {
    font-size: 10px;
    font-weight: 500;
    line-height: 100%;
  }
`;
