import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { DSButton, DSModal } from '@hundred5/design-system';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { FormikInputField } from '@/features/common';
import { IPipelineCategory } from '@/features/pipeline/types';
import { useJobOpeningIdOrNull } from '@/hooks/router';

interface RenameModalProps {
  categoryId?: string;
  categories: IPipelineCategory[];
  open: boolean;
  onSave: (name: string, category: IPipelineCategory) => void;
  onClose: () => void;
}

const validationSchema = yup.object().shape({
  stageName: yup.string().required('Name is required'),
});

export function RenameModal({
  categoryId,
  categories,
  onClose,
  onSave,
  open,
}: RenameModalProps) {
  const category = categories.find(category => category.id === categoryId);
  const jobOpeningId = useJobOpeningIdOrNull();
  if (!categoryId || !category) {
    return null;
  }

  return (
    <Formik<{ stageName: string }>
      initialValues={{
        stageName: category?.name,
      }}
      validationSchema={validationSchema}
      onSubmit={values => {
        onSave(values.stageName, category);
        amplitude?.logEvent('pipeline/step renamed', {
          'job opening id': jobOpeningId,
          'pipeline step': category.name,
          'pipeline step new name': values.stageName,
        });
      }}
    >
      <DSModal open={open} onClose={onClose} contentStyle={{ maxWidth: 442 }}>
        <DSModal.CloseButton small fixed onClick={onClose} />
        <Header>Edit name</Header>
        <DSModal.Separator />
        <Form>
          <Content>
            <FormikInputField
              label="Name"
              name="stageName"
              placeholder="Stage name"
              required
            />
          </Content>
          <Footer>
            <DSButton type="submit">Save</DSButton>
          </Footer>
        </Form>
      </DSModal>
    </Formik>
  );
}

const Header = styled.h2`
  margin-bottom: 16px;
  margin-top: 0;
`;

const Content = styled.div`
  margin-bottom: 24px;
  margin-top: 16px;
`;

const Footer = styled(DSModal.Footer)`
  gap: 8px;
`;
