import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';
import { useHistory } from 'react-router';

import { useWorkspaceStatsQuery } from '@/features/workspace';
import { useWorkspaceIdOrNull } from '@/hooks/router';

interface ActionBannerProps {
  icon?: React.ReactNode;
  title: string;
  subTitle: string;
  actionTitle: string;
  onClick?: () => void;
  isGetStartedBanner?: boolean;
}

export function ActionBanner({
  icon,
  title,
  subTitle,
  actionTitle,
  onClick,
  isGetStartedBanner,
}: ActionBannerProps) {
  const workspaceId = useWorkspaceIdOrNull();
  const history = useHistory();
  const workspaceStatsQuery = useWorkspaceStatsQuery({
    workspaceId: workspaceId ?? undefined,
  });
  const workspaceStats = workspaceStatsQuery.isSuccess
    ? workspaceStatsQuery.data
    : undefined;
  const showGetStartedBanner = workspaceStats?.totalJobOpenings === 0;

  const handleClick = isGetStartedBanner
    ? () => {
        amplitude?.logEvent('settings - get started button press');
        history.push(`/admin/${workspaceId}/openings/`);
      }
    : onClick;

  if (isGetStartedBanner && !showGetStartedBanner) {
    return null;
  }

  return (
    <Container>
      <Wrapper>
        {icon && <Icon>{icon}</Icon>}
        <TextContainer>
          <Title>{title}</Title>
          <Description>{subTitle}</Description>
        </TextContainer>
      </Wrapper>
      <DSButton variant="primary" onClick={handleClick}>
        {actionTitle}
      </DSButton>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.peach[50]};
  box-shadow: ${props => props.theme.shadow.card};
  padding: 36px;
  align-items: center;
  border-radius: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  margin-right: 48px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.div`
  color: ${props => props.theme.colors.purple[100]};
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  margin-bottom: 4px;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${props => props.theme.colors.purple[60]};
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.purple[100]};

  @media all and (min-width: 769px) {
    margin-right: 24px;
  }
`;
