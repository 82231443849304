import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { DSTextArea } from '@hundred5/design-system';
import TextInput from 'react-autocomplete-input';
import AutosizeTextArea from 'react-textarea-autosize';

import { useWorkspaceUsersQuery, WorkspaceUser } from '@/features/workspace';

interface AutocompleteTextAreaProps {
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
  onKeyUp?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onMouseUp?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSelect?: (user: WorkspaceUser) => void;
  workspaceUsers?: WorkspaceUser[];
}

/**
 * Wrapper for a library we use for autocompletion, fetches users on it's own if not provided with one
 * */
export function AutocompleteTextArea({
  workspaceUsers,
  onSelect,
  ...rest
}: AutocompleteTextAreaProps) {
  const workspaceUsersQuery = useWorkspaceUsersQuery({
    enabled: !workspaceUsers,
  });

  const options = useMemo(() => {
    if (workspaceUsers) {
      return workspaceUsers.map(user => `@${user.name}`);
    }

    if (!workspaceUsersQuery.isSuccess) {
      return [];
    }

    return workspaceUsersQuery.data.map(user => `@${user.name}`);
  }, [workspaceUsersQuery.isSuccess, workspaceUsersQuery.data, workspaceUsers]);

  const handleSelect = useCallback(
    (_trigger: string, slug: string) => {
      if (onSelect) {
        // casted as non-null as there's no way to select a user that is not in that list
        const selectedUser = (workspaceUsers ?? workspaceUsersQuery.data!).find(
          user => user.name === slug.replace('@', '')
        )!;
        onSelect(selectedUser);
      }

      return slug;
    },
    [onSelect, workspaceUsersQuery.data, workspaceUsers]
  );

  return (
    <Container>
      {/* @ts-ignore type definitions in lib seem wrong */}
      <TextInput
        Component={TextAreaWithRef}
        matchAny={true}
        maxOptions={0}
        offsetX={10}
        offsetY={10}
        options={options}
        passThroughEnter={false}
        trigger="@"
        changeOnSelect={handleSelect}
        {...rest}
      />
    </Container>
  );
}

const Container = styled.div`
  .react-autocomplete-input {
    border-radius: 8px;
    box-shadow: ${props => props.theme.shadow.popup};
    background-color: ${props => props.theme.colors.white};
    border: none;
    padding: 0;

    & > li {
      padding: 5px 20px;

      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &.active {
        background-color: ${props => props.theme.colors.purple[10]};
        color: ${props => props.theme.colors.purple[100]};
      }
    }
  }
`;

const TextArea = styled(DSTextArea.withComponent(AutosizeTextArea))`
  margin-top: 12px;
  margin-bottom: 12px;
  min-height: 96px;
  border: 1px solid ${props => props.theme.colors.purple[40]};
  border-radius: 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${props => props.theme.colors.purple[100]};
  padding: '0px';
  cursor: text;

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

const TextAreaWithRef = React.forwardRef((props: any, ref: any) => (
  <TextArea ref={ref} {...props} />
));
