import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { sortBy } from 'lodash';

import { TId } from '@/features/common';
import {
  createPipelineCategory,
  deletePipelineCategory,
  fetchPipelineCategories,
  IPipelineCategory,
  updatePipelineCategory,
} from '@/features/pipeline';
import { useDeleteTestMutation } from '@/features/test';

export const PIPELINE_CATEGORIES_KEY = ['pipeline-categories'];

export const usePipelineCategoriesQuery = (
  params: { jobOpeningId: TId },
  options?: UseQueryOptions
) => {
  return useQuery(
    [...PIPELINE_CATEGORIES_KEY, params.jobOpeningId],
    () => fetchPipelineCategories({ jobOpeningId: params.jobOpeningId }),
    options as any
  );
};

export const useUpdatePipelineCategoryMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation(updatePipelineCategory, {
    onMutate: async ({ jobOpeningId, category: updatedCategory }) => {
      const queryKey = [...PIPELINE_CATEGORIES_KEY, jobOpeningId];
      // cancel queries to avoid overwriting optimistic update
      await queryClient.cancelQueries({
        queryKey,
      });

      // manually update state
      const previousCategories =
        queryClient.getQueryData<IPipelineCategory[]>(queryKey) ?? [];

      const previousCategoriesWithoutUpdated = previousCategories.filter(
        category => category.id !== updatedCategory.id
      );

      const updatedCategories = sortBy(
        [...previousCategoriesWithoutUpdated, updatedCategory],
        'orderWeight'
      );

      queryClient.setQueryData(queryKey, updatedCategories);

      // return previous state to be able to rollback in case of error
      return { previousCategories, queryKey };
    },
    onError: (_err, _variables, context) => {
      queryClient.setQueryData(context!.queryKey, context!.previousCategories!);
    },
    onSettled: (_data, _err, variables) => {
      queryClient.invalidateQueries([
        ...PIPELINE_CATEGORIES_KEY,
        variables.jobOpeningId,
      ]);
    },
    ...(options as any),
  });
};

export const useRemoveTestFromPipelineCategoryMutation = (
  params: { jobOpeningId: TId },
  options?: UseMutationOptions
) => {
  const updatePipelineCategoryMutation = useUpdatePipelineCategoryMutation();
  const deleteTestMutation = useDeleteTestMutation();

  return useMutation(
    async ({
      category,
      testId,
    }: {
      category: IPipelineCategory;
      testId: TId;
    }) => {
      await updatePipelineCategoryMutation.mutateAsync({
        jobOpeningId: params.jobOpeningId,
        category: { ...category, testId: null },
      });

      await deleteTestMutation.mutateAsync(testId);
    },
    { ...(options as any) }
  );
};

export const useCreatePipelineCategoryMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation(createPipelineCategory, {
    onSuccess: (_data, { jobOpeningId }) => {
      queryClient.invalidateQueries([...PIPELINE_CATEGORIES_KEY, jobOpeningId]);
    },
    ...(options as any),
  });
};

export const useDeletePipelineCategoryMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation(deletePipelineCategory, {
    onSuccess: (_data, { jobOpeningId }) => {
      queryClient.invalidateQueries([...PIPELINE_CATEGORIES_KEY, jobOpeningId]);
    },
    ...(options as any),
  });
};
