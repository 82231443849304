import React from 'react';
import styled from '@emotion/styled';
import { format } from 'date-fns/format';

interface JobOpeningArchivedTagProps {
  closedAt?: Date | null;
  tooltipPosition?: string;
}

export function JobOpeningArchivedTag({
  closedAt,
  tooltipPosition = 'top',
}: JobOpeningArchivedTagProps) {
  return !!closedAt ? (
    <Tag
      data-rh-at={tooltipPosition}
      data-rh={`Archived from ${format(closedAt, 'MMMM dd, yyyy')}`}
    >
      Archived
    </Tag>
  ) : null;
}

const Tag = styled.span`
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.purple[40]};
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 500;
  padding: 0 8px;
  border-radius: 8px;
`;
