import styled from '@emotion/styled';

export const Box = styled.article<{ active?: boolean }>`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.purple[10]};
  border-color: ${({ theme, active }) => active && theme.colors.purple[40]};

  box-shadow: 0 1px 4px 0 rgba(44, 19, 56, 0.1);
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
  padding: 8px 8px 12px 8px;
  position: relative;
  width: 276px;

  &:hover {
    border-color: ${({ theme, active }) => !active && theme.colors.purple[20]};
  }
`;
