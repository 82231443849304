import React from 'react';
import styled from '@emotion/styled';
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import { TId, useDocumentTitle } from '@/features/common';
import { InterviewPageHeader, InterviewSettings } from '@/features/interview';

function InterviewPage() {
  useDocumentTitle('Interview');

  const { url } = useRouteMatch();
  const { interviewId } = useParams<{ interviewId: TId }>();

  return (
    <>
      <InterviewPageHeader interviewId={interviewId} />
      <PageContent>
        <Switch>
          <Route path={`${url}/settings`}>
            <InterviewSettings interviewId={interviewId} />
          </Route>

          <Redirect from={`${url}`} to={`${url}/settings`} exact />
        </Switch>
      </PageContent>
    </>
  );
}

const PageContent = styled.div`
  padding: 24px;
`;

export default InterviewPage;
