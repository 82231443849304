import React from 'react';
import '@emotion/core';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';

import { Environment } from '@/features/common/components/environment';

import App from './components/app/App';
import './external';
import { ErrorFallback, LoadErrorFallback } from './features/common';
import configureSentry from './sentry';
import './smartlook';

import './fonts.css';
import './index.css';

const history = createBrowserHistory();

configureSentry(history);

const rootEl = document.getElementById('root');
const root = createRoot(rootEl!);

root.render(
  <Sentry.ErrorBoundary
    fallback={({ error, eventId, resetError }) => {
      if (
        (error as Error).message.includes(
          'Failed to fetch dynamically imported module'
        ) ||
        (error as Error).message.includes('not a valid JavaScript MIME type')
      ) {
        return <LoadErrorFallback />;
      }
      return <ErrorFallback eventId={eventId} resetError={resetError} />;
    }}
  >
    <Environment>
      <App />
    </Environment>
  </Sentry.ErrorBoundary>
);
