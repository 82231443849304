import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  DSButton,
  DSColumn,
  DSContentBlock,
  DSLanguageSelector,
  DSRow,
  TLocale,
} from '@hundred5/design-system';
import { Form, Formik } from 'formik';
import { omit } from 'lodash';
import { array, object, string } from 'yup';

import {
  AddMoreOptions,
  DeleteIconButton,
  FormikCheckboxField,
  FormikRadioField,
  PlanTag,
  PromptIfDirty,
} from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import {
  IJobOpeningAttachment,
  JobOpeningEmailNotifications,
  TJobOpeningLoginMethod,
  useJobOpeningEmailNotificationsQuery,
  useJobOpeningQuery,
  useUpdateJobOpeningEmailNotificationsMutation,
  useUpdateJobOpeningMutation,
} from '@/features/job-opening';
import { useMeQuery } from '@/features/me';
import { useJobOpeningPermission } from '@/features/permissions';
import { usePreviewFeatureModal } from '@/features/preview-feature/hooks';
import { useWorkspaceUsersQuery } from '@/features/workspace';
import { usePlanLimits } from '@/hooks/planLimits';
import { useJobOpeningId } from '@/hooks/router';
import { useUpsellModal } from '@/hooks/upsellModal';

import { AddAttachmentTypeModal } from './ui';

type Settings = {
  loginMethods: TJobOpeningLoginMethod[];
  isAttachmentsEnabled: boolean;
  attachments: IJobOpeningAttachment[];
  locale: string | null;
  emailNotifications: JobOpeningEmailNotifications;
};

const validationSchema = object().shape({
  loginMethods: array()
    .of(string())
    .required('Choose at least one authentication method!'),
});

const locales: TLocale[] = [
  { locale: 'pt', label: 'Brasilian Portugese' },
  { locale: 'en', label: 'English' },
  { locale: 'et', label: 'Estonian' },
  { locale: 'fr', label: 'French' },
  { locale: 'de', label: 'German' },
  { locale: 'hu', label: 'Hungarian' },
  { locale: 'lv', label: 'Latvian' },
  { locale: 'lt', label: 'Lithuanian' },
  { locale: 'pl', label: 'Polish' },
  { locale: 'ru', label: 'Russian' },
  { locale: 'sr', label: 'Serbian' },
  { locale: 'sk', label: 'Slovak' },
  { locale: 'es', label: 'Spanish' },
  { locale: 'sv', label: 'Swedish' },
  { locale: 'tr', label: 'Turkish' },
];

export function JobOpeningSettingsOther() {
  const [openAddAttachmentTypeModal, setOpenAddAttachmentTypeModal] = useState(
    false
  );
  const jobOpeningId = useJobOpeningId();
  const canUpdateJobOpenings = useJobOpeningPermission()(
    'job opening',
    'update'
  );
  const canReadNotifications = useJobOpeningPermission()(
    'email notification',
    'read'
  );
  const canUpdateNotifications = useJobOpeningPermission()(
    'email notification',
    'update'
  );
  const canAccessFeature = usePlanLimits();
  const { openUpsellModal } = useUpsellModal();
  const showPreviewFeatureModal = usePreviewFeatureModal();
  const { showFlashMessage } = useFlashMessages();

  const jobOpeningQuery = useJobOpeningQuery();
  const jobOpeningMutation = useUpdateJobOpeningMutation({
    jobOpeningId,
    onSuccess: () => showFlashMessage({ type: 'saved' }),
  });
  const jobOpeningEmailNotificationsQuery = useJobOpeningEmailNotificationsQuery(
    { enabled: !!canReadNotifications }
  );
  const jobOpeningEmailNotificationsMutation = useUpdateJobOpeningEmailNotificationsMutation();
  const workspaceUsersQuery = useWorkspaceUsersQuery();
  const meQuery = useMeQuery();
  const myEmail = workspaceUsersQuery.data?.find(
    user => user.id === meQuery.data?.id
  )?.email;

  const initialSettings: Settings = {
    loginMethods: jobOpeningQuery.data?.loginMethods || [],
    isAttachmentsEnabled: !!jobOpeningQuery.data?.isAttachmentsEnabled,
    attachments: jobOpeningQuery.data?.attachments || [],
    locale: jobOpeningQuery.data?.locale || 'en',
    emailNotifications: jobOpeningEmailNotificationsQuery.data || {
      frequency: 'immediate',
      content: 'all',
    },
  };

  const handleSubmit = async (values: Settings) => {
    await jobOpeningMutation.mutateAsync({
      jobOpeningId,
      settings: omit(values, 'emailNotifications'),
    });

    if (canUpdateNotifications) {
      await jobOpeningEmailNotificationsMutation.mutateAsync(
        values.emailNotifications
      );
    }
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={initialSettings}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, dirty }) => (
        <Form>
          <PromptIfDirty />
          <DSContentBlock>
            <DSContentBlock.Title>Other</DSContentBlock.Title>
            <DSContentBlock.Content>
              <SectionLabel>Authentication method for candidates</SectionLabel>
              <DSColumn gap="8px">
                <FormikCheckboxField
                  name="loginMethods"
                  label="GitHub"
                  value="github"
                  hideErrorMsg
                  disabled={!canUpdateJobOpenings}
                />
                <FormikCheckboxField
                  name="loginMethods"
                  label="LinkedIn"
                  value="linkedin"
                  hideErrorMsg
                  disabled={!canUpdateJobOpenings}
                />
                <FormikCheckboxField
                  name="loginMethods"
                  label="Facebook"
                  value="facebook"
                  hideErrorMsg
                  disabled={!canUpdateJobOpenings}
                />
                <FormikCheckboxField
                  errorAbsolute
                  name="loginMethods"
                  label={
                    <>
                      Email
                      <StyledPlanTag />
                    </>
                  }
                  value="email"
                  onChange={event => {
                    if (canAccessFeature('candidate_email_login'))
                      setFieldValue(
                        'loginMethods',
                        event.target.checked
                          ? [...values.loginMethods, 'email']
                          : values.loginMethods.filter(item => item !== 'email')
                      );
                    else openUpsellModal('premium_feature');
                  }}
                  disabled={!canUpdateJobOpenings}
                />
              </DSColumn>
              <SectionLabel withTopMargin>Attachments</SectionLabel>
              <DSColumn gap="8px">
                <FormikCheckboxField
                  name="isAttachmentsEnabled"
                  label={
                    <>
                      Allow candidates to upload attachments after finishing a
                      test
                      <StyledPlanTag />
                    </>
                  }
                  onChange={event => {
                    if (canAccessFeature('candidate_attachments'))
                      setFieldValue(
                        'isAttachmentsEnabled',
                        event.target.checked
                      );
                    else showPreviewFeatureModal.open('attachments');
                  }}
                  disabled={!canUpdateJobOpenings}
                />
                <IndentationColumn gap="8px">
                  {values.attachments.map((attachment, indx) => (
                    <AttachmentTypeRow key={attachment.name}>
                      <FormikCheckboxField
                        name={`attachments[${indx}].isEnabled`}
                        label={attachment.name}
                        disabled={
                          !canAccessFeature('candidate_attachments') ||
                          !values.isAttachmentsEnabled ||
                          !canUpdateJobOpenings
                        }
                      />
                      {indx > 2 && canAccessFeature('candidate_attachments') && (
                        <DeleteIconButton
                          disabled={!values.isAttachmentsEnabled}
                          onClick={() => {
                            const newAttachments = [...values.attachments];
                            newAttachments.splice(indx, 1);
                            setFieldValue('attachments', newAttachments);
                          }}
                        />
                      )}
                    </AttachmentTypeRow>
                  ))}
                  <AddMoreOptions
                    onClick={() => setOpenAddAttachmentTypeModal(true)}
                    disabled={
                      !canAccessFeature('candidate_attachments') ||
                      !values.isAttachmentsEnabled ||
                      values.attachments.length >= 6
                    }
                    tooltip={
                      values.attachments.length >= 6
                        ? 'Remove one or more custom attachment types. You can only have three custom attachment types.'
                        : undefined
                    }
                  />
                  <AddAttachmentTypeModal
                    open={openAddAttachmentTypeModal}
                    onClose={() => setOpenAddAttachmentTypeModal(false)}
                    onSave={attachmentType => {
                      setFieldValue('attachments', [
                        ...values.attachments,
                        {
                          name: attachmentType,
                          enabled: false,
                        },
                      ]);
                      setOpenAddAttachmentTypeModal(false);
                    }}
                  />
                </IndentationColumn>
              </DSColumn>

              <SectionLabel withTopMargin>
                Email notifications for<EmailTag>{myEmail}</EmailTag>
              </SectionLabel>
              <DSRow gap="12px" xsWrap>
                <DSColumn size={8} md={12} xs={24} gap="8px">
                  <SubSectionLabel>Frequency</SubSectionLabel>
                  <FormikRadioField
                    name="emailNotifications.frequency"
                    label="Immediately"
                    value="immediate"
                    disabled={!canUpdateNotifications}
                  />
                  <FormikRadioField
                    name="emailNotifications.frequency"
                    label="Daily summary"
                    value="daily"
                    disabled={!canUpdateNotifications}
                  />
                  <FormikRadioField
                    name="emailNotifications.frequency"
                    label="Weekly summary"
                    value="weekly"
                    disabled={!canUpdateNotifications}
                  />
                  <FormikRadioField
                    name="emailNotifications.frequency"
                    label="No emails"
                    value="off"
                    disabled={!canUpdateNotifications}
                  />
                </DSColumn>
                <DSColumn xs={24} gap="8px">
                  <SubSectionLabel>Content</SubSectionLabel>
                  <FormikRadioField
                    name="emailNotifications.content"
                    label="All new candidates"
                    value="all"
                    disabled={!canUpdateNotifications}
                  />
                  <FormikRadioField
                    name="emailNotifications.content"
                    label="Candidates over threshold"
                    value="possible-hires"
                    disabled={!canUpdateNotifications}
                  />
                </DSColumn>
              </DSRow>

              <SectionLabel withTopMargin>
                User interface language that candidates will see on the test and
                results pages
              </SectionLabel>
              <LanguageSelectorRow>
                <DSLanguageSelector
                  variant="flag"
                  selectedLocale={values.locale}
                  locales={locales}
                  onChange={item => setFieldValue('locale', item?.locale)}
                  disabled={!canUpdateJobOpenings}
                />
              </LanguageSelectorRow>
            </DSContentBlock.Content>
            {canUpdateJobOpenings && (
              <DSContentBlock.Footer>
                <DSButton type="submit" disabled={!dirty}>
                  Save changes
                </DSButton>
              </DSContentBlock.Footer>
            )}
          </DSContentBlock>
        </Form>
      )}
    </Formik>
  );
}

const SectionLabel = styled.div<{ withTopMargin?: boolean }>`
  font-weight: 700;
  font-size: 1rem;
  color: ${props => props.theme.typography.colorPrimary};
  margin-bottom: 10px;
  margin-top: ${props => props.withTopMargin && '30px'};
`;

const SubSectionLabel = styled.div`
  font-weight: 500;
  font-size: ${10 / 14}rem;
  text-transform: uppercase;
  color: ${props => props.theme.typography.colorPrimary};
`;

const IndentationColumn = styled(DSColumn)`
  margin-left: 20px;
`;

const AttachmentTypeRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EmailTag = styled.span`
  color: ${props => props.theme.typography.colorPrimary};
  background: ${props => props.theme.colors.purple[5]};
  border-radius: 8px;
  font-weight: 500;
  font-size: ${10 / 14}rem;
  padding: 4px 8px;
  margin-left: 8px;
  vertical-align: top;
  line-height: 1.5rem;
`;

const LanguageSelectorRow = styled.div`
  width: 210px;
`;

const StyledPlanTag = styled(PlanTag)`
  margin-left: 8px;
`;
