import React from 'react';
import { SplitFactoryProvider } from '@splitsoftware/splitio-react';

import { useWorkspaceId } from '../../hooks/router';

interface Props {
  children: React.ReactNode;
}

const WorkspaceSplit: React.FC<Props> = props => {
  const workspaceId = useWorkspaceId();

  const splitConfig: SplitIO.IBrowserSettings = {
    core: {
      authorizationKey: import.meta.env.VITE_SPLIT_API_KEY || 'localhost',
      trafficType: 'workspace',
      key: workspaceId,
    },
    startup: {
      readyTimeout: 1,
    },
    storage: {
      type: 'LOCALSTORAGE',
    },
  };

  return (
    <SplitFactoryProvider
      config={splitConfig}
      updateOnSdkReady
      updateOnSdkTimedout
    >
      {props.children}
    </SplitFactoryProvider>
  );
};

export default WorkspaceSplit;
