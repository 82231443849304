import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { DSButton, DSField, DSInput } from '@hundred5/design-system';
import { format } from 'date-fns/format';
import { isBoolean } from 'lodash';
import { DateUtils } from 'react-day-picker';

import { DayPicker } from '@/features/common';
import { useClickOutside } from '@/features/common/hooks/use-click-outside';

import TimePicker from '../../../../share/ui/time-picker';

interface DateAndTimePickerProps {
  open: boolean;
  onClose: () => void;
  onScheduleSend: (time: Date) => void;
}

export function DateAndTimePicker({
  open,
  onClose,
  onScheduleSend,
}: DateAndTimePickerProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const today = new Date();
  const [selectedDay, setSelectedDay] = useState<Date>(today);
  const [submissionError, setSubmissionError] = useState<boolean>();
  useClickOutside(containerRef, onClose);

  const handleDayClick = (day: Date) => {
    if (DateUtils.isFutureDay(day) || DateUtils.isSameDay(day, today)) {
      day.setHours(selectedDay.getHours());
      day.setMinutes(selectedDay.getMinutes());

      setSelectedDay(day);
      handleSubmissionError(day);
    }
  };

  const handleSubmissionError = (day: Date) => {
    if (isBoolean(submissionError)) {
      if (day.getTime() > Date.now()) {
        setSubmissionError(false);
      } else {
        setSubmissionError(true);
      }
    }
  };

  return open ? (
    <Container ref={containerRef}>
      <Pickers>
        <DayPicker
          onDayClick={handleDayClick}
          selectedDays={selectedDay}
          disabledDays={{ before: new Date() }}
        />

        <Separator />
        <Inputs>
          <DSField label="Date" for="date">
            <Input value={format(selectedDay, 'dd MMM yyyy')} disabled />
          </DSField>
          <DSField label="Time" for="time">
            <TimePickerInput
              value={format(selectedDay, 'HH:mm')}
              onChange={(event, value) => {
                const hoursAndMinutes = value.split(':');
                const day = new Date(selectedDay.getTime());

                day.setHours(Number(hoursAndMinutes[0]));
                day.setMinutes(Number(hoursAndMinutes[1]));

                setSelectedDay(day);
                handleSubmissionError(day);
              }}
            />
          </DSField>

          {submissionError ? (
            <ErrorMessage>Please choose a future Date/Time</ErrorMessage>
          ) : null}
        </Inputs>
      </Pickers>
      <Buttons>
        <DSButton type="button" variant="tertiary" onClick={onClose}>
          Cancel
        </DSButton>
        <DSButton
          type="button"
          onClick={() => {
            if (selectedDay.getTime() > Date.now()) {
              onScheduleSend(selectedDay);
            } else {
              setSubmissionError(true);
            }
          }}
        >
          Schedule send
        </DSButton>
      </Buttons>
    </Container>
  ) : null;
}

const Container = styled.div`
  position: absolute;
  bottom: 69px;
  right: 38px;
  min-width: 584px;
  min-height: 380px;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(44, 19, 56, 0.2);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Pickers = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-left: 12px;
  }
`;

const Input = styled(DSInput)`
  width: 250px;
`;
const TimePickerInput = styled(TimePicker)`
  width: 250px;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 250px;
`;

const ErrorMessage = styled.div`
  font-size: 12px;
  color: ${props => props.theme.typography.colorError};
`;

const Separator = styled.div`
  width: 1px;
  height: 257px;
  background-color: ${props => props.theme.colors.purple[20]};
  margin-left: 16px;
  margin-right: 16px;
`;
