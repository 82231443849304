import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { useMeQuery } from '@/features/me';
import { useWorkspaceId } from '@/hooks/router';

import {
  fetchInboxItems,
  fetchInboxTotalUnread,
  markInboxItemsSeen,
} from '../api';
import { useInboxSearchParams } from '../hooks/params';

export const INBOX_KEY = ['inbox'];

export const useInboxItemsQuery = () => {
  const workspaceId = useWorkspaceId();
  const { searchParams } = useInboxSearchParams();

  return useQuery(
    [...INBOX_KEY, searchParams, workspaceId],
    () => fetchInboxItems(workspaceId, searchParams),
    {
      enabled: !!(searchParams && parseInt(searchParams.page)),
    }
  );
};

export const useInboxTotalUnreadQuery = () => {
  const workspaceId = useWorkspaceId();
  const me = useMeQuery();

  return useQuery(
    [...INBOX_KEY, 'totalUnread', workspaceId],
    () => fetchInboxTotalUnread(workspaceId),
    { enabled: me.data?.id !== '0' } // avoid making the request in support mode
  );
};

export const useMarkInboxItemsSeenMutation = (options?: UseMutationOptions) => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();
  const { searchParams } = useInboxSearchParams();

  return useMutation(markInboxItemsSeen, {
    onSuccess: (_, variables) => {
      if (!!variables?.itemsIds?.length) {
        queryClient.invalidateQueries([
          ...INBOX_KEY,
          searchParams,
          workspaceId,
        ]);
      }
      queryClient.invalidateQueries([...INBOX_KEY, 'totalUnread', workspaceId]);
    },
    ...(options as any),
  });
};
