import { AxiosResponse } from 'axios';
import { get } from 'lodash';

export class BaseError extends Error {
  response: AxiosResponse;
  type: string;
  errorId: string;
  status: number;

  constructor(response: AxiosResponse) {
    super();
    this.response = response;
    this.errorId = response.headers['th-request-id'];
    this.message = response.data.error.error || '';
    this.type = response.data.error.type || '';
  }
}

export class ServerError extends BaseError {}

export class AuthorizationError {}

export class NetworkError {}

export class ReCaptchaError extends Error {
  constructor(error) {
    super(error);
    this.name = 'ReCaptchaError';
  }
}

export class ClientError extends BaseError {
  constructor(response: AxiosResponse) {
    super(response);
    this.name = `ClientError: API request failed with status ${
      response.status
    } and ${response.data.error.type || 'unknown'} error type`;
  }
  get customerMessage() {
    return get(this.response, 'data.transaction_error.customer_message');
  }
}

export class MultiClientError extends BaseError {
  constructor(response: AxiosResponse) {
    super(response);
    this.name = 'MultiClientError';
  }

  get errors(): string[] {
    return get(this.response, 'data.errors', []).map(error => error.message);
  }
}

export const checkClientError = (
  error: any,
  type: string
): error is ClientError => {
  return error instanceof ClientError && error.type === type;
};

export class AccountsClientError extends Error {
  response: AxiosResponse;
  error: string;
  errorDescription: string;

  constructor(response: AxiosResponse) {
    super();
    this.response = response;
    this.error = response.data.error || '';
    this.errorDescription = response.data.error_description || '';
  }
}
